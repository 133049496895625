import React from 'react';
import ConditionalRender from '../modal/ConditionalRender';

const ShowRequirement: React.FC<{ text: string; isFulfilled: boolean }> = ({
  isFulfilled,
  text,
}) => {
  const textst = {
    color: isFulfilled
      ? 'var(--Sub-Color-Green-d1, #4DB972)'
      : 'var(--Greyscale-Color-Grey-6, #64686E)',
    fontFamily: 'Pretendard',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '-0.204px',
  };

  const render = (conditional: boolean) =>
    conditional ? (
      <div className="flex gap-1 items-center">
        <svg
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_275_320)">
            <mask
              id="mask0_275_320"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="8"
              height="8"
            >
              <path d="M8 0H0V8H8V0Z" fill="white" />
            </mask>
            <g mask="url(#mask0_275_320)">
              <path
                d="M1 4L3 6L7 2"
                stroke="#75D696"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </g>
          <defs>
            <clipPath id="clip0_275_320">
              <rect width="8" height="8" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <div style={textst}>{text}</div>
      </div>
    ) : (
      <div className="flex gap-1 items-center">
        <svg
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="4" cy="4" r="4" fill="#D8DDE3" />
        </svg>
        <div style={textst}>{text}</div>
      </div>
    );

  return <ConditionalRender conditional={isFulfilled} render={render} />;
};

export default ShowRequirement;
