import React, { useState } from 'react';
import ModalBase from '../common/ModalBase';
interface ModalProps {
  onClose: () => void;
}
const ReportDeleted: React.FC<ModalProps> = ({ onClose }) => {
  return (
    <ModalBase>
      <div className="flex flex-col gap-8 text-gray-600 bg-white p-2 rounded-lg w-[341px] h-[229px] items-center justify-center ">
        <div className="text-center  font-bold text-gray-600 ">
          <p>삭제 되었습니다 </p>
        </div>

        <div className="flex justify-end">
          <button
            onClick={onClose}
            type="submit"
            style={{ background: '#4046E3' }}
            className="text-white font-bold w-[72px] h-[36px] py-1 px-2 rounded focus:outline-none focus:shadow-outline"
          >
            닫기
          </button>
        </div>
      </div>
    </ModalBase>
  );
};

export default ReportDeleted;
