import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import { AccountIcon1 } from './components/icons/AccountIcon1';
import { NewsfeedIcon1 } from './components/icons/NewsfeedIcon1';
import { ProductIcon1 } from './components/icons/ProductIcon1';
import './CBCVTab.css';
import { PieChartIcon } from './components/icons/PieChartIcon/PieChartIcon';

interface TabItem {
  text: string;
  url: string;
}

const tabs: TabItem[] = [
  { text: '분석 요청 관리', url: '/admin' },
];
//admin 페이지의 tab입니다
export const CBCVAdminTab = (): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const handleTabClick = (index: number) => {
    setSelectedTab(index);
  };

  return (
    <div className="cvcvtab min-h-screen max-h-full">
      <div className="headless-wrapper">
        <img
          className="headless-logo"
          alt="Headless logo"
          src="/logo.png"
        />
      </div>
      <div className="line-box">
        <div className="line" />
      </div>
      <div className="tab-item">
        <NewsfeedIcon1 className="icon" />
        <div className="sec-text">브랜드 가치 분석</div>
      </div>

      {tabs.slice(0, 3).map((item, index) => (
        <div
          className={`tab-item ${selectedTab === index ? 'tab-item-selected' : ''}`}
          key={index}
          onClick={() => handleTabClick(index)}
        >
          <div className="icon"></div>
          <Link to={item.url} className="link">
            <div className="text">{item.text}</div>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default CBCVAdminTab;
