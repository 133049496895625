import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

export interface Tab {
  title: string;
  link: string;
}

interface Props {
  tabs: Tab[];
  mainClassName?: string;
  itemClassName?: string;
  activeClassName?: string;
}

const TabComponent: React.FC<Props> = ({
  tabs,
  mainClassName = 'flex justify-center text-base font-bold items-center gap-9',
  itemClassName = 'flex justify-center items-center text-center text-white hover:text-zinc-500',
  activeClassName = 'text-zinc-500 hover:text-zinc-200',
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const activeIndex = tabs.findIndex(tab => tab.link === location.pathname);
  const isActive = (index: number) => index === activeIndex;

  React.useEffect(() => {
    if (activeIndex === -1 && tabs.length > 0) {
      navigate(tabs[0].link + location.search);
    }
  }, [location.pathname, tabs, activeIndex, navigate, location.search]);

  return (
    <div className={mainClassName}>
      {tabs.map((tab, index) => {
        const linkWithQuery = tab.link + location.search;
        return (
          <Link
            to={linkWithQuery}
            key={tab.link}
            className={isActive(index) ? ` ${itemClassName}` : activeClassName}
          >
            {tab.title}
          </Link>
        );
      })}
    </div>
  );
};

export default TabComponent;
