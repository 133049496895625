import React, { useEffect, useState } from 'react';
import { Button } from '../Button';
import './SignUp.css';
import Checkbox from '../common/Checkbox';
import { useAuth } from '../Contexts/AuthContext';
import { MdPermIdentity } from 'react-icons/md';
import { identity } from 'lodash';
import { formatKoreanPhoneNumber, hasLatter, hasNumber, hasSpecialChar } from '../common/Auxiliary';
import { IAuthorization, IUser } from '../../types';
import ShowRequirement from '../common/ShowRequirement';
import { useModalMessage } from '../common/CBCVModalMessageProvider';
import { error } from 'console';
import { Link, useNavigate } from 'react-router-dom';
import LogIn from './Login';

export const SignUp = () => {
  const [agreedAll, setAgreedAll] = useState<boolean>(false);
  const [agreedTerm, setAgreedTerm] = useState<boolean>(false);
  const [agreePrivacyTerms, setPrivacyTerms] = useState<boolean>(false);

  const [agreeAllMarketing, setAllMarketing] = useState<boolean>(false);
  const [agreedEmail, setAgreedEmail] = useState<boolean>(false);
  const [agreedText, setAgreedText] = useState<boolean>(false);

  const [id, setID] = useState<string>('');
  const [passwordA, setPasswordA] = useState<string>('');
  const [passwordB, setPasswordB] = useState<string>('');

  const [email, setEmail] = useState<string>('');
  const [otp, setOTP] = useState<string>('');

  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [comName, setComName] = useState<string>('');

  const [isEmailConformed, setEmailConformed] = useState<boolean>(false);
  /*  const [isIDConformed, setIDConformed] = useState<boolean>(false);
   */
  const [isIDValid, setIDValid] = useState(false);
  const [anySpecialCharacter, setSCAndMinimal] = useState(true);
  const [isPasswordValid, setPasswordValid] = useState(false);
  const [doPasswordsMatch, setPasswordsMatch] = useState(false);

  const [isAskOTP, setRequireOTP] = useState<boolean>(false);
  const [remainingTime, setRemainingTime] = useState(3600);

  const [isSignUpClicked, setSignUpClicked] = useState<boolean>(false);

  const navi = useNavigate();

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const isAbleToJoin = () => {
    return (
      /*       isIDConformed && */
      isIDValid &&
      isPasswordValid &&
      doPasswordsMatch &&
      isEmailConformed &&
      phoneNumber.length > 0 &&
      comName.length > 0 &&
      agreedTerm &&
      agreePrivacyTerms
    );
  };

  const { duplicateIdentityCheck, getEmailOTP, setEmailOTP, normalSignIn, signUp, duplicateEmailCheck } = useAuth();

  const onSetAll = (checked: boolean) => {
    setAgreedAll(checked);
    setAgreedTerm(checked);
    setPrivacyTerms(checked);
    setAllMarketing(checked);
    setAgreedEmail(checked);
    setAgreedText(checked);
  };

  const onMarketingAll = (checked: boolean) => {
    setAgreedEmail(checked);
    setAgreedText(checked);
  };

  const { updateMessage } = useModalMessage();

  useEffect(() => {
    setAgreedAll(
      agreedTerm &&
      agreePrivacyTerms &&
      agreeAllMarketing &&
      agreedEmail &&
      agreedText,
    );
  }, [
    agreedTerm,
    agreePrivacyTerms,
    agreeAllMarketing,
    agreedEmail,
    agreedText,
  ]);

  useEffect(() => {
    setAllMarketing(agreedEmail && agreedText);
  }, [agreedEmail, agreedText]);

  useEffect(() => {

    const specialCharsRegex = /[!@#\$%\^\&*\)\(+=._-]+/;
    if (specialCharsRegex.test(id) || (id.length > 0 && id.length < 4)) {
      setSCAndMinimal(true);
    }
    else {
      setSCAndMinimal(false);
    }

/////////////////////////////////////////////////


    if (/^[A-Za-z0-9]{4,}$/.test(id)) 
      {
      setIDValid(true);
    } else {
      setIDValid(false);
    }
  }, [id]);

  useEffect(() => {
    let countValid = 0;

    if(hasNumber(passwordA))
      countValid+=1;

    if(hasSpecialChar(passwordA))
      countValid+=1;
  
    if(hasLatter(passwordA))
      countValid+=1;

    setPasswordValid(passwordA.length >= 8 && countValid > 1);
    setPasswordsMatch(passwordA === passwordB);
  }, [passwordA, passwordB]);


  const duplicateIdentity = async () => {
    if (id === '') return;

    if (await duplicateIdentityCheck(id)) {
      updateMessage('error', '중복된 아이디 입니다.');
    } else {
      /* setIDConformed(true); */
      updateMessage('confirm', '사용할 수 있는 아이디입니다.');
    }
  };

  const getOTP = async () => {
    if (await duplicateEmailCheck(email)) {
      updateMessage('error', '이미 가입되어 있는 이메일 주소입니다.');
      return;
    }

    try {
      await getEmailOTP(email);

      updateMessage('confirm', '인증번호가 발송되었습니다.');
      setRequireOTP(true);
      setEmailConformed(false);
      const timerInterval = setInterval(() => {
        setRemainingTime((prevTime) => {
          if (prevTime > 0) {
            return prevTime - 1;
          } else {
            clearInterval(timerInterval);
            return 0;
          }
        });
      }, 1000);
    } catch (error) {
      updateMessage('error', '올바르지 않은 이메일 주소');
    }
  };

  const sendOTP = async () => {
    try {
      await setEmailOTP(email, otp);

      updateMessage('confirm', '인증되었습니다.');

      setEmailConformed(true);
      setRequireOTP(false);
    } catch (error) {
      updateMessage('error', '올바르지 않은 인증번호');
    }
  };
  const onSignUp = async () => {
    const auth: IAuthorization = { identity: id, password: passwordA };
    const user: IUser = {
      email: email,
      agreed_email: agreedEmail,
      agreed_term: agreedTerm,
      agreed_text: agreedText,
      agree_privacy_terms: agreePrivacyTerms,
      company_name: comName,
      phone_number: phoneNumber,
    };

    if (!isIDValid) {
      updateMessage('error', '아이디는 최소 4자 이상 영문과 숫자만 입력해 주세요.');
    }


    setSignUpClicked(false);

    try {
      await signUp({ auth, user });
      await normalSignIn(auth.identity, auth.password, false);

      window.location.href = '../connect-data';
    } catch (error) {
      updateMessage('error', '인터넷 연결을 확인해 주세요.');
    }
  };

  const renderCheckbox = (checked: boolean) => {
    return !checked ? (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.5"
          y="0.5"
          width="19"
          height="19"
          rx="3.5"
          stroke="#D8DDE3"
        />
      </svg>
    ) : (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_413_680)">
          <path
            d="M16 0H4C1.79086 0 0 1.79086 0 4V16C0 18.2091 1.79086 20 4 20H16C18.2091 20 20 18.2091 20 16V4C20 1.79086 18.2091 0 16 0Z"
            fill="#4046E3"
          />
          <path
            d="M15.7087 6.284C15.5184 6.10174 15.2651 6 15.0017 6C14.7382 6 14.4849 6.10174 14.2947 6.284L8.75168 11.659L5.70868 8.70901C5.51843 8.52676 5.26515 8.42499 5.00168 8.42499C4.73822 8.42499 4.48493 8.52676 4.29468 8.70901C4.20215 8.7976 4.12851 8.90401 4.0782 9.02182C4.02789 9.13963 4.00195 9.2664 4.00195 9.3945C4.00195 9.5226 4.02789 9.64937 4.0782 9.76718C4.12851 9.88499 4.20215 9.99143 4.29468 10.08L7.33768 13.03L8.04568 13.717C8.23572 13.8989 8.48862 14.0004 8.75168 14.0004C9.01474 14.0004 9.26765 13.8989 9.45768 13.717L10.1657 13.03L15.7087 7.65601C15.8014 7.5674 15.8751 7.46092 15.9255 7.34302C15.9759 7.22511 16.0019 7.09823 16.0019 6.97C16.0019 6.84178 15.9759 6.71489 15.9255 6.59698C15.8751 6.47908 15.8014 6.3726 15.7087 6.284Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_413_680">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  };

  return (
    <div className="flex">
      <div className="bg-gray-600 w-[506px]" />
      <div className="bg-white ">
        <div className="pt-14 pb-14 pr-14 flex justify-end items-center gap-5 ">
          <div className="text-wrapper-2">이미 회원가입을 하셨나요?</div>
          <Link to={'/login'}>
            <Button
              visible={true}
              className="button-instance"
              property1="variant-3"
              text="로그인"
            /></Link>
        </div>
        <div className="sign-up">
          <div className="div">
            <div className="tittle pb-7">회원가입</div>
            <div className="frame-10">
              <div className="text-wrapper-3">아이디</div>
              <div className="text-wrapper-4">*</div>
              {isSignUpClicked && id.length < 1 ? (
                <div className="text-wrapper-6 h-4">
                  아이디를 입력해 주세요.
                </div>
              ) : (
                <div className="h-4"></div>
              )}
            </div>
            <div className="flex gap-2 justify-between">
              <input
                type="text"
                pattern="[A-Za-z0-9]+"
                className="div-wrapper"
                placeholder="아이디를 입력해 주세요."
                value={id}
                onChange={(e) => setID(e.target.value)}
              />
              <Button
                className="design-component-instance-node"
                property1="default"
                text="중복확인"
                onClick={duplicateIdentity}
                visible={false}
              />
            </div>
            {anySpecialCharacter ?
              (<div className="text-wrapper-6 h-4">
                최소 4자 이상 영문과 숫자만
              </div>) :
              (<ShowRequirement
                isFulfilled={isIDValid}
                text={'최소 4자 이상 영문과 숫자만'}
              ></ShowRequirement>)
            }

            <div className="h-6" />

            <div className="frame-2">
              <div className="text-wrapper-3">비밀번호</div>
              <div className="text-wrapper-4">*</div>
              {isSignUpClicked && passwordA.length < 1 ? (
                <div className="text-wrapper-6 h-4">
                  비밀번호를 입력해 주세요.
                </div>
              ) : (
                <div className="h-4"></div>
              )}
            </div>

            <input
              type="password"
              className="div-wrapper"
              placeholder="비밀번호를 입력해 주세요."
              value={passwordA}
              onChange={(e) => setPasswordA(e.target.value)}
            />
            <ShowRequirement
              isFulfilled={isPasswordValid}
              text={'최소 8자 이상'}
            />
            <ShowRequirement
              isFulfilled={isPasswordValid}
              text={'영문, 숫자, 특수 문자 (!@#$%^_)조합 중 2개 이상'}
            />
            <div className="h-6" />

            <div className="frame-3">
              <div className="text-wrapper-3">비밀번호 재입력</div>
              <div className="text-wrapper-4">*</div>
            </div>
            <div className="flex gap-2">
              <div className="text-wrapper-19">최소 8자 이상</div>
              <p className="text-wrapper-20">
                영문, 숫자, 특수 문자 (!@#$%^_)조합 중 2개 이상
              </p>
            </div>

            <input
              type="password"
              className="div-wrapper"
              placeholder="위에 입력한 비밀번호를 다시 한번 더 입력해 주세요."
              value={passwordB}
              onChange={(e) => setPasswordB(e.target.value)}
            />
            {!doPasswordsMatch ? (
              <div className="text-wrapper-6 h-4">
                비밀번호가 일치하지 않아요.
              </div>
            ) : (
              <div className="text-wrapper-6 h-4"> </div>
            )}

            <div className="h-6" />
            <div className="frame-9">
              <div className="text-wrapper-3">이메일</div>
              <div className="text-wrapper-4">*</div>
              {isSignUpClicked && !isEmailConformed ? (
                <div className="text-wrapper-6 h-4">
                  이메일 인증이 필요해요.
                </div>
              ) : (
                <div className="h-4"></div>
              )}
            </div>
            <p className="text-wrapper-11">
              분석 결과 안내를 위해 이메일을 꼭 입력해 주세요
            </p>

            <div className="flex gap-3">
              <input
                placeholder="example@headless.co.kr"
                className="div-wrapper"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></input>
              <Button
                className="button-2"
                property1="default"
                text="인증번호 받기"
                visible={false}
                onClick={getOTP}
              />
            </div>
            <div className="h-4"></div>

            <div className="flex gap-3">
              <div>
                <div className="flex gap-3 w-[480px]">
                  <div className="div-wrapper">
                    <input
                      className="w-full"
                      type="text"
                      placeholder="인증번호를 입력해 주세요."
                      value={otp}
                      onChange={(e) => setOTP(e.target.value)}
                    ></input>
                    {isAskOTP && !isEmailConformed ? (
                      <div className="text-wrapper-17 h-4 ">
                        {formatTime(remainingTime)}
                      </div>
                    ) : (
                      <div className="h-4 " />
                    )}
                  </div>

                  <Button
                    className="button-3"
                    property1="default"
                    text="인증하기"
                    onClick={sendOTP}
                    visible={false}
                  />
                </div>
                {isAskOTP && !isEmailConformed ? (
                  <div className="text-wrapper-17 h-6">
                    인증번호는 60분간 유효합니다.
                  </div>
                ) : (
                  <div className="h-6" />
                )}
              </div>
            </div>
            <div className="h-6" />
            <div className="flex gap-3 w-full">
              <div className="w-[260px] ">
                <div className="flex gap-1">
                  <div className="text-wrapper-13">담당자 연락처</div>
                  <div className="text-wrapper-4">*</div>
                  {isSignUpClicked && phoneNumber.length < 1 ? (
                    <div className="text-wrapper-6 h-4">
                      담당자 연락처는 필수에요.
                    </div>
                  ) : (
                    <div className="h-4"></div>
                  )}
                </div>
                <input
                  type="text"
                  placeholder="000-0000-0000"
                  className="div-wrapper"
                  value={phoneNumber}
                  onChange={(e) =>
                    setPhoneNumber(formatKoreanPhoneNumber(e.target.value))
                  }
                ></input>
              </div>
              <div className="w-1/2 ">
                <div className="flex gap-1 w-full">
                  <div className="text-wrapper-13">기업명</div>
                  <div className="text-wrapper-4">*</div>
                  {isSignUpClicked && comName.length < 1 ? (
                    <div className="text-wrapper-6 h-4">기업명은 필수에요.</div>
                  ) : (
                    <div className="h-4"></div>
                  )}
                </div>
                <input
                  type="text"
                  placeholder="주식회사 000"
                  className="div-wrapper"
                  value={comName}
                  onChange={(e) => setComName(e.target.value)}
                ></input>
              </div>
            </div>
            <div className="h-6" />

            <div className="frame-5 pb-5">
              <div className="text-wrapper-3">약관동의</div>
              <div className="text-wrapper-4">*</div>
              {isSignUpClicked && (!agreedTerm || !agreePrivacyTerms) ? (
                <div className="text-wrapper-6 h-4">
                  헤드리스 서비스를 이용하려면 이용약관과 개인정보처리방침은
                  필수 동의 항목입니다.
                </div>
              ) : (
                <div className="h-4"></div>
              )}
            </div>
            <div className="flex">
              <div className="frame-6">
                <Checkbox
                  checked={agreedAll}
                  onChange={onSetAll}
                  renderCheckbox={renderCheckbox}
                />
                <div className="text-wrapper-7">
                  아래 약관에 모두 동의합니다.
                </div>
              </div>
            </div>

            <div className="overlap">
              <div className="frame-7">
                <Checkbox
                  checked={agreedTerm}
                  onChange={setAgreedTerm}
                  renderCheckbox={renderCheckbox}
                />
                <p className="div-2">
                  <span className="span">[필수] </span>
                  <span className="text-wrapper-8">헤드리스 이용약관 동의</span>
                </p>
              </div>
              <a href="/termsofuse" target="_blank" rel="noopener noreferrer">
              <img
                className="plus-button-icon"
                alt="Plus button icon"
                src="/plus-button-icon.svg"
              />    </a>
            </div>

            <div className="overlap-group">
              <div className="frame-7">
                <Checkbox
                  checked={agreePrivacyTerms}
                  onChange={setPrivacyTerms}
                  renderCheckbox={renderCheckbox}
                />
                <p className="div-2">
                  <span className="span">[필수] </span>
                  <span className="text-wrapper-8">
                    헤드리스 개인정보처리방침 동의
                  </span>
                </p>
              </div>
              <a href="/privacypolicy" target="_blank" rel="noopener noreferrer">
                <img
                  className="img"
                  alt="Plus button icon"
                  src="/plus-button-icon.svg"
                />
              </a>
            </div>
            <div className="overlap-2">
              <div className="flex justify-between">
                <div className="frame-7">
                  <Checkbox
                    checked={agreeAllMarketing}
                    onChange={onMarketingAll}
                    renderCheckbox={renderCheckbox}
                  />
                  <p className="div-2">
                    <span className="text-wrapper-9">[선택] </span>
                    <span className="text-wrapper-8">
                      마케팅 정보 수신 동의
                    </span>
                  </p>
                </div>
                <img
                  className="img"
                  alt="Plus button icon"
                  src="/plus-button-icon.svg"
                />
              </div>
              <div className="pl-10 flex gap-5">
                <div className="frame-8">
                  <Checkbox
                    checked={agreedText}
                    onChange={setAgreedText}
                    renderCheckbox={renderCheckbox}
                  />
                  <div className="text-wrapper-3">문자</div>
                </div>
                <div className="frame-8">
                  <Checkbox
                    checked={agreedEmail}
                    onChange={setAgreedEmail}
                    renderCheckbox={renderCheckbox}
                  />
                  <div className="text-wrapper-3">이메일</div>
                </div>
              </div>
            </div>

            <div className="h-6"></div>
            {isAbleToJoin() ? (
              <button onClick={onSignUp} className="overlap-4 text-wrapper-10">
                가입하기
              </button>
            ) : (
              <button
                onClick={() => setSignUpClicked(true)}
                className="overlap-3 text-wrapper-10"
              >
                가입하기
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
