import React, { createContext, useContext, useState, ReactNode } from 'react';

interface AppState {
    user: {
      name: string;
      email: string;
      id: number
    };
}

const initialState: AppState = {
  user: {
    name: '',
    email: '',
    id: -1,
  },
};


const AppContext = createContext<{
  state: AppState;
  setState: React.Dispatch<React.SetStateAction<AppState>>;
}>({
  state: initialState,
  setState: () => null,
});


export const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, setState] = useState<AppState>(initialState);

  return (
    <AppContext.Provider value={{ state, setState }}>
      {children}
    </AppContext.Provider>
  );
};


export const useAppContext = () => useContext(AppContext);