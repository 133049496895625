import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import PortalModal from '../common/PortalModal';

interface DropdownOption {
  key: string;
  label: string;
}

interface BottomElementProps {
  children: React.ReactNode;
}

interface DropdownProps {
  options: DropdownOption[];
  selectedOptionKey: string;
  buttonClassName?: string;
  dropdownClassName?: string;
  itemClassName?: string;
  itemHoverClassName?: string;
  itemSelectedClassName?: string;
  onOptionChange: (key: string) => void;
}

const BottomElement: React.FC<BottomElementProps> = ({ children }) => {
  return ReactDOM.createPortal(children, document.body);
};

const Dropdown: React.FC<DropdownProps> = ({
  options,
  selectedOptionKey,
  buttonClassName,
  dropdownClassName,
  itemClassName,
  itemSelectedClassName,
  itemHoverClassName,
  onOptionChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(selectedOptionKey);
  const [hoveredOption, setHoveredOption] = useState<string | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    onOptionChange(selected);
  }, [selected]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div ref={dropdownRef}>
      <button
        onClick={() => {
          setIsOpen(!isOpen);
          setSelected(selected);
        }}
        className={` ${buttonClassName}`}
      >
        {options.find((option) => option.key === selected)?.label}
        <svg
          className="ml-2 w-4 h-4"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          {isOpen ? (
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5 15l7-7 7 7"
            />
          ) : (
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 9l-7 7-7-7"
            />
          )}
        </svg>
      </button>
      {isOpen && (
        <div style={{ position: 'absolute', zIndex: 10 }}>
          <div className={dropdownClassName}>
            {options.map((option) => (
              <div
                key={option.key}
                className={`${option.key === hoveredOption ? itemHoverClassName : option.key === selected ? itemSelectedClassName : itemClassName}`}
                onClick={() => {
                  setIsOpen(false);
                  setSelected(option.key);
                }}
                onMouseEnter={() => setHoveredOption(option.key)}
                onMouseLeave={() => setHoveredOption(null)}
              >
                {option.label}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
