import React from 'react';

interface CheckboxProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  renderCheckbox: (checked: boolean) => React.ReactNode;
}

const Checkbox: React.FC<CheckboxProps> = ({ checked, onChange, renderCheckbox }) => {
  const handleCheckboxChange = () => 
    {
    onChange(!checked);
  };
  
  return (
    <div className='cursor-pointer select-none' onClick={handleCheckboxChange}>
      {renderCheckbox(checked)}
    </div>
  );
};

export default Checkbox;