import React from 'react';

import { useNavigate } from 'react-router-dom';

interface ButtonProps {
  children: React.ReactNode;
}

const BackButton: React.FC<ButtonProps> = ({ children }) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <button onClick={handleGoBack} className='cursor-pointer'>
      {children}
    </button>
  );
};
export default BackButton;