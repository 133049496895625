import { Button } from '../../components/Button';
import './SSRegiModal.css';
import React, { useEffect, useState } from 'react';
import ModalBase from '../common/ModalBase';
import useRESTAPI from '../Contexts/RESTAPI';
import { useAuth } from '../Contexts/AuthContext';
import {API_HOST} from "../../config";

interface ApiKeys {
  id: number;
  cbcv_user_id: number;
  api_kind: string;
  api_verified: boolean;
  api_keys: string;
  ss_application_id: string;
  ss_application_secret: string;
  cafe24_mall_id: string;
  cp_seller_code: string;
  cp_access_key: string;
  cp_secret_key: string;
}

interface CURegiModalProps {
  onClose: () => void;
}

export const SSRegiModal = ({ onClose }: CURegiModalProps): JSX.Element => {
  const { user_id } = useAuth();
  const { items, fetchItems, addItem, editItem, removeItem } =
    useRESTAPI(API_HOST + '/api/api-keys');
  const [idConfirmed, setIdConfirmed] = useState<boolean>();
  const [sellerID, setSellerID] = useState<string>();
  const [secretKey, setSecretKey] = useState<string>();

  const [sellerIDError, setSellerIDError] = useState<string>();
  const [secretKeyError, setSecretKeyError] = useState<string>();

  useEffect(() => {
    fetchItems({ cbcv_user_id: user_id, api_kind: 'SMARTSTORE' });
  }, []);

  useEffect(() => {
    setSellerIDError(undefined);
    setSecretKeyError(undefined);
  }, [sellerID, secretKey]);

  function onCancel() {
    onClose();
  }

  async function onConnect() {
    const url = API_HOST + '/api/cbcv-api/cbcv-check';
    const data = {
      api_mode: 'check',
      channel: 'naver',
      user_id: user_id,

    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();
      console.log(responseData.result_code )
      if(responseData.result_code ===500)
      {
        throw new Error('500 error ');
      }

      const data2 = await fetchItems({
        cbcv_user_id: user_id,
        api_kind: 'naver',
      });
      editItem(data2[0].id, {
        api_verified: true,
      });



      setIdConfirmed(true);
      onClose();
    } catch (error) {
      console.error('Error sending POST request:', error);
      setIdConfirmed(false);

      setSellerIDError('올바르지 않은 키 또는 아이디');
      setSecretKeyError('올바르지 않은 키 또는 아이디');
    }

  }

  const onConfirmed = () => {
    if (!sellerID || sellerID === undefined) {
      setSellerIDError('아이디를 입력해 주세요');
    }
    if (!secretKey || secretKey === undefined) {
      setSecretKeyError('키를 입력해 주세요');
    }

    if (
      !sellerID ||
      sellerID === undefined ||
      !secretKey ||
      secretKey === undefined
    ) {
      return;
    }
    handlePostRequest();
  };

  const handlePostRequest = async () => {
    const target = await fetchItems({
      cbcv_user_id: user_id,
      api_kind: 'naver',
    });

    if('length' in target && target.length > 0 )
      await removeItem(target[0].id);

    await addItem({
      cbcv_user_id: user_id,
      api_kind: 'naver',
      api_verified: false,
      ss_application_id: sellerID,
      ss_application_secret: secretKey,
    });
    setIdConfirmed(true);
  };

  return (
    <ModalBase>
      <div className="flex flex-col justify-between SSRegiModal w-[664px] h-[586px] rounded-lg bg-white">
        <div className="rounded-t-md">
          <div className="p-6">
            <div className="text-wrapper-14">스마트스토어 주문 데이터 연동</div>
            <div className="h-4"></div>
            <hr></hr>
            <div className="h-4"></div>
            <div className=" p-6   bg-gray-100 border border-gray-300 rounded-lg p-30">
              <div className="">애플리케이션 ID</div>
              <div className="h-[12px]" />
              <div className="flex gap-2">
                {idConfirmed ? (
                  <div className="bg-[#BCC2C9] border  rounded-md flex items-center h-10 w-full">
                    <div className="pl-7 text-gray-500">
                      API 연동용 판매자 ID를 입력해 주세요
                    </div>
                  </div>
                ) : (
                  <input
                    value={sellerID}
                    onChange={(e) => setSellerID(e.target.value)}
                    placeholder={
                      sellerIDError || '애플리케이션 ID를 입력해 주세요.'
                    }
                    type="text"
                    className={`bg-white border ${
                      sellerIDError
                        ? 'border-[#E53073] placeholder-[#E53073]'
                        : 'border-[#d8dde3]'
                    } rounded-[5px] flex items-center pl-[25px] h-[38px] w-full text-left 
                        ${sellerIDError ? 'text-[#E53073]' : 'text-gray-500'}`}
                  />
                )}
              </div>
              <div className="h-[20px]" />
              <div className="">애플리케이션 시크릿</div>
              <div className="h-[12px]" />
              <div className="flex gap-2">
                {idConfirmed ? (
                  <div className="bg-[#BCC2C9] border  rounded-md flex items-center h-10 w-full">
                    <div className="pl-7 text-gray-500">
                      API 연동용 판매자 ID를 입력해 주세요
                    </div>
                  </div>
                ) : (
                  <input
                    value={secretKey}
                    onChange={(e) => setSecretKey(e.target.value)}
                    placeholder={
                      secretKeyError || '애플리케이션 시크릿을 입력해 주세요.'
                    }
                    type="text"
                    className={`bg-white border ${
                      secretKeyError
                        ? 'border-[#E53073] placeholder-[#E53073]'
                        : 'border-[#d8dde3]'
                    } rounded-[5px] flex items-center pl-[25px] h-[38px] w-full text-left 
                        ${secretKeyError ? 'text-[#E53073]' : 'text-gray-500'}`}
                  />
                )}
              </div>
              <div className="h-[22px]" />
              <div className="flex justify-center items-center">
                <button
                  onClick={onConfirmed}
                  className="text-[#474A4E] w-[149px] h-[40px] border-[1px] border-gray-300 rounded-lg bg-white flex items-center justify-center "
                >
                  스마트스토어 연동
                </button>
              </div>
            </div>

            <div className="p-6">
              <div className="text-wrapper-16">스마트스토어 인증 가이드</div>
              <div className="h-2" />
              <div className="font-medium">
                <p className="">
                  아래 가이드 버튼을 통해 연결 가이드를 확인해 보세요.
                </p>
              </div>
              <div className="h-5" />
              <div className="rounded-md p-4 bg-[#F2F4F7] h-10 w-56 gap-2 flex text-center items-center justify-center">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask
                    id="mask0_45_3130"
                    maskUnits="userSpaceOnUse"
                    x="1"
                    y="0"
                    width="14"
                    height="16"
                  >
                    <path d="M14.75 0.25H1.25V15.75H14.75V0.25Z" fill="white" />
                  </mask>
                  <g mask="url(#mask0_45_3130)">
                    <path
                      d="M14 1H5V11H14V1Z"
                      stroke="#474A4E"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8 11H4C3.46957 11 2.96086 11.2107 2.58579 11.5858C2.21071 11.9609 2 12.4696 2 13C2 13.5304 2.21071 14.0391 2.58579 14.4142C2.96086 14.7893 3.46957 15 4 15H14C13.4564 14.461 13.105 13.7583 13 13"
                      stroke="#474A4E"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M1.99995 13V2.99995C1.99436 2.73577 2.04227 2.4732 2.1408 2.22802C2.23933 1.98284 2.38645 1.76013 2.57329 1.57329C2.76013 1.38645 2.98284 1.23933 3.22802 1.1408C3.4732 1.04227 3.73577 0.994361 3.99995 0.99995H8.99995"
                      stroke="#474A4E"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>
                <a
                  href={
                    'https://local-room-f00.notion.site/470a034d6bf04c9db2d933ebd35aa65b'
                  }
                >
                  <div>스마트스토어 인증 가이드</div>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full rounded-b-md flex justify-end p-2 gap-2 bg-slate-200">
          <button
            onClick={onCancel}
            className="bg-white border-gray-300 border-2 rounded-md w-[91px] h-[34px]  flex items-center justify-center"
          >
            취소
          </button>
          <button
            onClick={onConnect}
            className={`${idConfirmed ? ' bg-[#4046E3]' : 'bg-[#BCC2C9]'}  h-[34px] w-[96px] rounded-md text-white flex items-center justify-center`}
          >
            연결하기
          </button>
        </div>
      </div>
    </ModalBase>
  );
}; //idConfirmed
export default SSRegiModal;
