/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

interface Props {
  className: any;
}

export const ProductIcon1 = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={`product-icon-1 ${className}`}
      fill="none"
      height="18"
      viewBox="0 0 18 18"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_3_28480)">
        <mask className="mask" height="18" id="mask0_3_28480" maskUnits="userSpaceOnUse" width="18" x="0" y="0">
          <path className="path" d="M18 0H0V18H18V0Z" fill="white" />
        </mask>
        <g className="g" mask="url(#mask0_3_28480)">
          <path
            className="path"
            d="M13.5 5.5H16.5L15.5 16.5H2.5L1.5 5.5H13.5Z"
            stroke="#BCC2C9"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            className="path"
            d="M5.5 8.5C5.5 8.5 5.5 10.5 9 10.5C12.5 10.5 12.5 8.5 12.5 8.5"
            stroke="#BCC2C9"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            className="path"
            d="M4.5 5.5C4.5 5.5 4.5 1.5 9 1.5C13.5 1.5 13.5 5.5 13.5 5.5"
            stroke="#BCC2C9"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </g>
      </g>
      <defs className="defs">
        <clipPath className="clipPath" id="clip0_3_28480">
          <rect className="rect" fill="white" height="18" width="18" />
        </clipPath>
      </defs>
    </svg>
  );
};
