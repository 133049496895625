import React from 'react';
import ModalBase from '../common/ModalBase';

interface ModalProps {

  name: string;
  email: string;
  phoneNumber: string;
  onClose: () => void; 
}

const ComapanyInfoModal: React.FC<ModalProps> = ({

  name,
  email,
  phoneNumber,
  onClose,
}) => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  return (
    <ModalBase>
      <div className="flex flex-col text-gray-600 bg-white p-2 rounded-lg w-[341px] h-[229px] items-center justify-center">
        <div className="text-lg font-bold mb-4">업체 정보</div>
        <hr className="w-full" />
        <div className="h-4"></div>
        <form
          onSubmit={handleSubmit}
          className="flex text-sm w-5/6 flex-col items-start "
        >
          <div className="flex justify-center items-center ">
            <div className="block font-bold mb-2">기업명:</div>
            <div className="mb-2 p-1 ">{name}</div>
          </div>

          <div className="flex justify-center items-center ">
            <div className="block font-bold mb-2">담당자 연락처:</div>
            <div className="mb-2 p-1 ">{phoneNumber}</div>
          </div>

          <div className="flex justify-center items-center ">
            <div className="block font-bold mb-2">이메일:</div>
            <div className="mb-2 p-1 ">{email}</div>
          </div>
          <div className="flex justify-center w-full">
            <button
              onClick={onClose}
              type="submit"
              style={{ background: '#4046E3' }}
              className="text-white font-bold w-[72px] h-[36px] py-1 px-2 rounded focus:outline-none focus:shadow-outline"
            >
              닫기
            </button>
          </div>
        </form>
      </div>
    </ModalBase>
  );
};

export default ComapanyInfoModal;
