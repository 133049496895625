import React, { useEffect, useState } from 'react';
import TabComponent from '../common/TabComponent';
import TableComponent, { TableData, TableColumn } from './TableComponent';
import TittleBar from '../TittleBar';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../Contexts/AuthContext';
import useRESTAPI from '../Contexts/RESTAPI';
import { formatNumberWithCommas } from '../common/Auxiliary';
import CBCVTab from '../../CBCVTab';
import NoReport from '../common/Norepot';

import {API_HOST} from "../../config";
const ITEMS_PER_PAGE = 10;

export const SeasonalProducts = (): JSX.Element => {
  const navi = useNavigate();
  const { tab } = useParams();

  useEffect(() => {
    if (!tab) {
      const searchParams = new URLSearchParams(location.search);
      const searchString = searchParams.toString();
      navi(`/seasonal-products/all${searchString ? `?${searchString}` : ''}`);
    }
  }, [tab]);
  const { user_id ,admin_id} = useAuth();

  const { items: requests, fetchItems: fetchRequests } = useRESTAPI(API_HOST + '/api/cbcv-request');
  const [isRecv, setRecv] = useState<boolean>(false);
  useEffect(() => {

  }, [isRecv]);


  useEffect(()  => {
    const _ = async () => {
      if (user_id === undefined) 
        return;
         const data = await fetchRequests({ cbcv_user_id: user_id, status: 5 });
         console.log();
        if(!data || data.length ===0)
          setRecv(true);
    }
    _();

  }, [user_id, tab]);

  const [lastRequest, setLastRequests] = useState<any>();
  const [reportData, setReportData] = useState<any>();
  const [currentPages, setCurrentPages] = useState<{ [key: string]: number }>({
    seasonal_spring_top100_list: 1,
    seasonal_summer_top100_list: 1,
    seasonal_fall_top100_list: 1,
    seasonal_winter_top100_list: 1,
  });

  useEffect(() => {
    setLastRequests(requests[requests.length - 1]);
  }, [requests]);

  const location = useLocation();



  useEffect(() => {
    if (admin_id === undefined)
      return;
    const _ = async () => {
      const id = new URLSearchParams(location.search).get('request_id');
      if(id){
        const req = await fetchRequests({request_id:id})
        console.log(req);
        if(!req || req.length ===0)
          setRecv(true);

        setLastRequests(req[0]);
      }
    }
    _();
    
  }, [admin_id,tab]);

  useEffect(() => {
    if (lastRequest === undefined) return;
    const url = API_HOST + '/api/cbcv-api/product_seasonal_top100/';
    const _ = async () => {
      const data = {
        user_id: lastRequest.cbcv_user_id.id,
        request_id: lastRequest.request_id,
        channel_param: tab,
      };

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      const responseData = await response.json();

      if(responseData.message === 'there is no report available')
      {
        alert('데이터가 존재하지 않습니다')
        navi(-1)
      }
      else
      {
        setReportData(responseData);
      }
      setRecv(true)
    };
    _();
  }, [lastRequest]);

  const handlePageChange = (season: string, pageNumber: number) => {
    setCurrentPages((prevPages) => ({
      ...prevPages,
      [season]: pageNumber,
    }));
  };

  const getPaginatedData = (data: TableData[], season: string) => {

    const startIndex = (currentPages[season] - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;

    return data.slice(startIndex, endIndex);
  };

  if (!reportData) {
    return (
      <div className="flex  text-[#474A4E]">
        <CBCVTab></CBCVTab>
        <div className="BrandAnalysis w-full  min-w-[1200px]">
        <TittleBar tittle="상품 분석" />
          
        {isRecv ? <NoReport></NoReport> :  <div className='flex flex-col gap-11 h-full w-full justify-center items-center'/>}
        </div>
      </div>
    );
  }

  const columns: TableColumn[] = [
    { key: 'rank', label: '순위', width: 40 },
    { key: 'product_name', label: '상품명', width: 800 },
    { key: 'order_total', label: '전체 매출', width: 140 },
    { key: 'seasonal_order_portion', label: '매출 비율', width: 150 },
  ];

  const renderCol = (column: TableColumn) => {
    const needLine = column.key !== columns[0].key;

    const aa =
      column.key === columns[columns.length - 1].key
        ? 'border-gray-300 font-bold'
        : '';

    return (
      <div
        className={`bg-gray-200 ${aa} border-b-2 flex items-center justify-start w-full h-[48px]`}
      >
        {needLine ? <div className="bg-gray-400 h-2/4 w-[1px]"></div> : <></>}
        
        <div className="flex w-full justify-center">{column.label}</div>
      </div>
    );
  };

  const renderCell = (rowindex: number, rowData: TableData, column: TableColumn) => {
    let leftBorder =
      columns[0].key !== column.key && column.key !== columns[columns.length - 1].key
        ? ' border-l-2'
        : 'font-bold';

    let border = leftBorder;

    const bg =
      column.key !== columns[columns.length - 1].key
        ? ' bg-white'
        : ' border-gray-300 bg-gray-200';

    let text = rowData[column.key];
    if ('order_total' === column.key) {
      text = '₩ ' + formatNumberWithCommas(rowData[column.key]);
    }
    if ('seasonal_order_portion' === column.key) {
      text = (rowData[column.key] * 100).batterToFix(0).toString() + '%';
    }

    if(column.key === 'product_name')
      {
        return (
          <div className={`border-b-[1px] border-r-[1px] bg-white flex items-center justify-start w-full h-[39px]`}>
            {true ? <div className={'h-full w-[1px]'}></div> : <></>}
            <div className="w-full truncate flex justify-start pl-5"> {text}</div>
          </div>
        );
      }


    return (
      <div className={`border-b-[1px] border-r-[1px] bg-white flex items-center justify-start w-full h-[39px]`}>
        {true ? <div className={'h-full w-[1px]'}></div> : <></>}
        <div className="w-full flex justify-end pr-5"> {text}</div>
      </div>
    );
  };

  const seasons = ['봄', '여름', '가을', '겨울'];
  const seasonKeys = [
    'seasonal_spring_top100_list',
    'seasonal_summer_top100_list',
    'seasonal_fall_top100_list',
    'seasonal_winter_top100_list',
  ];

  const prevSvg = (
    <svg
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.00098 1L1.00098 5L5.00098 9"
        stroke="#474A4E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 5.00012L11 5.00012"
        stroke="#474A4E"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );

  const nextSvg = (
    <svg
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99902 9L10.999 5L6.99902 1"
        stroke="#474A4E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 4.99988L1 4.99988"
        stroke="#474A4E"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
  //      <div className="w-[1161px]">
  return (
    <div className="flex text-[#474A4E]">
      <CBCVTab />
      <div className="w-full">
        <TittleBar tittle="상품 분석" />
        <div className='flex w-full items-center justify-center'>
          <div className="w-[1161px] pt-[34px]">
            <div className="font-bold text-[26px] text-[#474a4e]">계절별 매출 TOP 100 상품</div>
            <div className="h-[86px]"></div>
            <TabComponent
              mainClassName="flex  text-[#7B828B] gap-5"
              activeClassName="h-10 px-4 text-[#7B828B]"
              itemClassName="h-10 font-bold px-4 text-[#4046E3] border-b-4 border-[#4046E3]"
              tabs={[
                { title: '전체', link: '/seasonal-products/all' },
                { title: '쿠팡', link: '/seasonal-products/coupang' },
                { title: '스마트스토어', link: '/seasonal-products/naver' },
                { title: '카페24', link: '/seasonal-products/cafe24' },
      /*           { title: '기타', link: '/seasonal-products/etc' }, */
              ]}
            />
            {seasons.map((season, index) => (
              <div key={index}>
                <div className="h-[40px]"></div>
                <div className="pl-3 m-3 border-l-4 font-bold border-gray-800">{season}</div>
                <TableComponent key={index}
                  columns={columns}
                  data={getPaginatedData(reportData.data[seasonKeys[index]], seasonKeys[index])}
                  className="text-sm border-2 rounded-lg"
                  renderCell={renderCell}
                  renderColumn={renderCol}
                />
                <div className="flex justify-center items-center p-4 pb-[60px]">
                  <div className="flex gap-2">
                    <button
                      onClick={() =>
                        handlePageChange(
                          seasonKeys[index],
                          Math.max(currentPages[seasonKeys[index]] - 1, 1)
                        )
                      }
                      className="flex justify-center items-center h-[40px] w-[40px] rounded-lg border-2 border-gray-300"
                    >
                      {prevSvg}
                    </button>
                    <button
                      onClick={() =>
                        handlePageChange(
                          seasonKeys[index],
                          Math.min(
                            currentPages[seasonKeys[index]] + 1,
                            Math.ceil(reportData.data[seasonKeys[index]].length / ITEMS_PER_PAGE)
                          )
                        )
                      }
                      className="flex justify-center items-center h-[40px] w-[40px] rounded-lg border-2 border-gray-300"
                    >
                      {nextSvg}
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default SeasonalProducts;
