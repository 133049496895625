import React, { ReactNode } from 'react';

interface ConditionalRenderProps {
  conditional: boolean;
  render: (conditional: boolean) => ReactNode;
}

const ConditionalRender: React.FC<ConditionalRenderProps> = ({ conditional, render }) => {
  return <>{render(conditional)}</>;
};
 
export default ConditionalRender;