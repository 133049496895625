import React, { useEffect, useState } from 'react';
import { Button } from '../Button';
import './auth.css';
import ModalBase from '../common/ModalBase';
import NewPasswordModal from '../modals/NewPasswordModal';
import ShowRequirement from '../common/ShowRequirement';
import { useAuth } from '../Contexts/AuthContext';
import { useParams } from 'react-router-dom';
import { useModalMessage } from '../common/CBCVModalMessageProvider';
import { hasLatter, hasNumber, hasSpecialChar } from '../common/Auxiliary';
export const NewPassword = (): JSX.Element => {
  const [passwordA, setPasswordA] = useState<string>('');
  const [passwordB, setPasswordB] = useState<string>('');
  const [isPasswordValid, setPasswordValid] = useState(false);
  const [doPasswordsMatch, setPasswordsMatch] = useState(false);
  const { newPassword } = useAuth();
  const { uuid } = useParams();
  const {updateMessage } = useModalMessage();
  //localhost/api/auth/newpassword?uuid=ca51d63f-eb76-4e4b-bcdf-f0e9b80fc305
  useEffect(() => {

    let countValid = 0;

    if(hasNumber(passwordA))
      countValid+=1;

    if(hasSpecialChar(passwordA))
      countValid+=1;
  
    if(hasLatter(passwordA))
      countValid+=1;

    setPasswordValid(passwordA.length >= 8 && countValid > 1);
    setPasswordsMatch(passwordA === passwordB);




  }, [passwordA, passwordB]);



/* 
 
     */
  const onNewPasswordClicked = async () => {
    if (uuid&& doPasswordsMatch && isPasswordValid) {
      try {
        newPassword(uuid,passwordA);
      } catch (error) {
        updateMessage('error','올바르지 않은 접근.');
  
      }

    } else {
      updateMessage('error','비밀번호가 확인이 필요합니다.');
   
    }
  };
  return (
    <div className="login">
      <div className="div">
        <img
          className="img"
          alt="Element"
          src="/logo-b.png"
        />
        <div className="h-8"></div>
        <div className="overlap-group">
          <div className="text-wrapper-6">비밀번호 재설정</div>{' '}
          <div className="flex flex-col gap-2 ">
            <div className="frame-3 ">
              <div className="font-medium">새 비밀번호</div>
            </div>
            <div className="div-wrapper">
              <input
                type="password"
                onChange={(e) => setPasswordA(e.target.value)}
                placeholder="비밀번호를 입력해 주세요."
                className="text-wrapper-5"
              />
            </div>
            <div className="text-xs flex gap-4">
              <div className="flex gap-1 items-center ">
                <ShowRequirement
                  isFulfilled={isPasswordValid}
                  text={'최소 8자 이상'}
                />
              </div>
              <div className="flex gap-1 items-center ">
                <ShowRequirement
                  isFulfilled={isPasswordValid}
                  text={'영문, 숫자, 특수 문자 (!@#$%^_)조합 중 2개 이상'}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2 ">
            <div className="frame-4">
              <div className="font-medium">새 비밀번호 재입력</div>
            </div>
            <div>
              <div className="frame-2">
                <input
                  type="password"
                  onChange={(e) => setPasswordB(e.target.value)}
                  placeholder="위에 입력한 비밀번호를 다시 한번 더 입력해 주세요."
                  className="text-wrapper-5 w-full"
                />
              </div>
              {doPasswordsMatch ? (
                <div className="pt-3 h-4"></div>
              ) : (
                <div className="pt-3 h-4 text-pink-600 text-sm font-bold">
                  비밀번호가 일치하지 않아요.
                </div>
              )}
            </div>
          </div>
          <button
            onClick={() => {
              onNewPasswordClicked();
            }}
            className="overlap-2 text-wrapper-2"
          >
            비밀번호 재설정하기
          </button>
        </div>
        <div className="h-16"></div>
        <div className="flex gap-2"></div>
        {/* <NewPasswordModal></NewPasswordModal> */}
      </div>
      {/*<ModalBase>
        <div className="group">
          <div className="overlap-group">
            <div className="p-5">
              <div className="text-wrapper-8">비밀번호 재설정이 필요해요!</div>
              <div className="vector" />
              <div className="frame-8">
                <p className="text-wrapper-9">
                  우리 회원님의 소중한 개인정보 보호를 위해 비밀번호 오류가 5번
                  이상인 경우 재설정하도록 되어 있어요. 비밀번호 재설정을 이용해
                  주세요.
                </p>
              </div>
            </div>
            <div className="frame-wrapper p-5">
              <div className="button-wrapper">
                <button style={{ backgroundColor: '#4046E3' }} className="w-52 h-10 rounded-lg text-white">비밀번호 재설정하기</button>
              </div>
            </div>
          </div>
        </div>
      </ModalBase>*/}
    </div>
  );
};

export default NewPassword;
