import React, { useState } from 'react';
import ModalBase from '../common/ModalBase';

interface ModalProps {
  onClose: () => void; 
}


const ReportCompleted: React.FC<ModalProps> = ({onClose}) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [description, setDescription] = useState('');

 /*  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    ('이름:', name);
    ('이메일:', email);
    ('설명:', description);
  }; */

  return (
    <ModalBase>
      <div className="flex flex-col gap-3 text-gray-600 bg-white p-2 rounded-lg w-[341px] h-[229px] items-center justify-center ">
        <div className="text-center  font-bold text-gray-600 ">
          <p>보고서 발행이 완료되어 </p>
          <p>고객이 보고서를 열람할 수 있습니다</p>
        </div>
        <div className="text-gray-500  font-Pretendard text-sm leading-24 tracking-tighter">
          고객에게 안내 메일이 전송 되었습니다.
        </div>

        <div className="flex justify-end">
          <button
            onClick={onClose}
            type="submit"
            style={{ background: '#4046E3' }}
            className="text-white font-bold w-[72px] h-[36px] py-1 px-2 rounded focus:outline-none focus:shadow-outline"
        
          >
            닫기
          </button>
        </div>
      </div>
    </ModalBase>
  );
};

export default ReportCompleted;
