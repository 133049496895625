import React from 'react';

export interface TableColumn {
  key: string;
  label: string;
  width?: number;
}
export interface TableData {
  [key: string]: any;
}
export interface TableComponentProps {
  columns: TableColumn[];
  data: TableData[];
  className: string;
  renderCell: (
    rowIndex: number,
    rowData: TableData,
    column: TableColumn,
  ) => React.ReactNode;
  maxHeight?: number;
  renderColumn: (column: TableColumn) => React.ReactNode;
}

const TableComponent: React.FC<TableComponentProps> = ({
  columns,
  data,
  className,
  maxHeight,
  renderCell,
  renderColumn,
}) => {
  return (
    <div className={className}>
      <div className="flex overflow-clip justify-start">
        {columns.map((column) => (
          <div
            key={column.key}
            style={{ width: '100%', minWidth: column.width }}
          >
            {renderColumn(column)}
          </div>
        ))}
      </div>
      <div style={{height:maxHeight, overflowX:'clip', overflowY:'scroll'}} className={`overflow-y-scroll`}>
    
        {data.map((rowData, rowIndex) => (
          <div
            className={`flex justify-start`}
            key={`row_${rowIndex}`}
          >
            {columns.map((column) => (
              <div
                key={`${rowIndex}_${column.key}`}
                style={{ width: '100%', minWidth: column.width }}
              >
                {renderCell(rowIndex, rowData, column)}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TableComponent;
