import React from 'react';
import ModalBase from '../common/ModalBase';
import { useNavigate } from 'react-router-dom';
import { IoMdClose } from "react-icons/io";

interface WorngPasswordProps {
  onClose: () => void;
}

export const WorngPassword = ({ onClose }: WorngPasswordProps): JSX.Element => {
  return (
    <ModalBase>
      <div className="w-[600px] h-[204px]">
        <div className=" w-[602px] h-[204px] top-0 left-0">
          <div className="p-4 w-[600px] h-[204px] bg-white rounded-md shadow-[0px_2px_6px_#33363b33]">
            <div className="flex justify-between items-center h-[26px] top-[17px] left-5 [font-family:'Pretendard-Bold',Helvetica] font-bold text-[#474a4e] text-base tracking-[-0.27px] leading-[26px] whitespace-nowrap">
              비밀번호 재설정이 필요해요!
              <IoMdClose onClick={onClose} className='cursor-pointer h-full' />
            </div>
            
            <div className="h-2" />
            <div
              className="w-full bg-gray-400 h-[1px]"
            />
            <div className="h-2" />
            
            <p className=" w-[560px] mt-[-1.00px] [font-family:'Pretendard-Medium',Helvetica] font-medium text-[#474a4e] text-sm tracking-[-0.24px] leading-[22px]">
              우리 회원님의 소중한 개인정보 보호를 위해 비밀번호 오류가 5번
              이상인 경우 재설정하도록 되어 있어요. 비밀번호 재설정을 이용해
              주세요.
            </p>
            <div className="h-12" />
            <div className="flex justify-end w-full h-[60px] ">
              <div className="inline-flex items-start gap-2  top-3 left-[421px]">
                <a href="/findmyiD">
                  <button className="py-1 px-2 text-white rounded-md bg-[#4046E3] ">
                    비밀번호 재설정하기
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalBase>
  );
};