export interface IUser {
    id?: number;
    email: string;
    company_name: string;
    phone_number: string;
    agreed_term: boolean;
    agree_privacy_terms: boolean;
    agreed_email: boolean;
    agreed_text: boolean;

  }

  export interface IAuthorization {
    id?: number;
    identity: string;
    password: string;
  }
  
  export enum EDataStatus {
    requested, 
    transmitting, 
    transmitted,
    analyzing,
    analyzed, 
    reported,
}
