import React, { useEffect, useRef, useState } from 'react';
import './FutureClientSegmentation.css';
import TabComponent from '../common/TabComponent';
import TableComponent, { TableData, TableColumn } from './TableComponent';
import TittleBar from '../TittleBar';
import CBCVTab from '../../CBCVTab';
import { useAuth } from '../Contexts/AuthContext';
import useRESTAPI from '../Contexts/RESTAPI'; import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { formatNumberWithCommas } from '../common/Auxiliary';
import NoReport from '../common/Norepot';
import SaveAsPDFButton from '../common/SaveAsPDFButton';
import {API_HOST} from "../../config";

export const FutureClientSegmentation = (): JSX.Element => {
  const { tab } = useParams();
  const { user_id,admin_id } = useAuth();
  const navi = useNavigate()
  const printRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!tab) {
      const searchParams = new URLSearchParams(location.search);
      const searchString = searchParams.toString();
      navi(`/future-customers/all${searchString ? `?${searchString}` : ''}`);
    }
  }, [tab]);

  const {
    items: requests,
    fetchItems: fetchRequests,
  } = useRESTAPI(API_HOST + '/api/cbcv-request');


  const [isRecv, setRecv] = useState<boolean>(false);
  useEffect(() => {

  }, [isRecv]);

  useEffect(()  => {
    const _ = async () => {
      if (user_id === undefined) 
        return;
         const data = await fetchRequests({ cbcv_user_id: user_id, status: 5 });
         console.log();
        if(!data || data.length ===0)
          setRecv(true);
    }
    _();

  }, [user_id, tab]);

  const [lastRequest, setLastRequests] = useState<any>();

  useEffect(() => {

    setLastRequests(requests[requests.length - 1]);
  }, [requests]);

  const [reportData, setReportData] = useState<any>();

  const location = useLocation();
  useEffect(() => {
    if (admin_id === undefined)
      return;
    const _ = async () => {
      const id = new URLSearchParams(location.search).get('request_id');
      if(id){
        const req = await fetchRequests({request_id:id})
        console.log(req);
        setLastRequests(req[0]);
      }
    }
    _();
    
  }, [admin_id,tab]);
  
  useEffect(() => {
    if (lastRequest === undefined)
      return;
    const url = API_HOST + '/api/cbcv-api/customer_future_segment/'
    const _ = async () => {

      const data = {
        user_id: lastRequest.cbcv_user_id.id,
        request_id: lastRequest.request_id,
        channel_param: tab,
      }

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
      const responseData = await response.json();
  
      if(responseData.message === 'there is no report available')
        {
          navi(-1)
          alert('데이터가 존재하지 않습니다')

        }
        else
        {
          setReportData(responseData);
        }
        setRecv(true);
    };

    _();
  }, [lastRequest]);




  const columns: TableColumn[] = [
    { key: 'label', label: '', width: 250 },
    { key: 'vvip', label: 'VVIP (상위 1%)', width: 100 },
    { key: 'vip', label: 'VIP (상위 10%)', width: 100 },
    { key: 'gold', label: 'GOLD (상위 30%)', width: 100 },
    { key: 'silver', label: 'SILVER (상위 50%)', width: 100 },
    { key: 'total', label: '전체', width: 100 },
  ];


  if (!reportData) {
    return (
      <div className="flex  text-[#474A4E]">
        <CBCVTab></CBCVTab>
        <div className="BrandAnalysis w-full  min-w-[1200px]">
        <TittleBar tittle="브랜드 고객 분석" />
          
          {isRecv? <NoReport></NoReport> :  <div className='flex flex-col gap-11 h-full w-full justify-center items-center'/>}
        </div>
      </div>
    );
  }

  const data = reportData.data.segment_table;

  const renderCol = (column: TableColumn) => {
    const needLine = column.key !== columns[0].key;

    const aa =
      column.key === columns[columns.length - 1].key
        ? 'border-gray-300    font-bold'
        : '';
    return (
      <div
        className={`bg-gray-200 ${aa} border-b-2 flex items-center justify-start w-full  h-[48px]`}
      >
        {needLine ? <div className="bg-gray-400 h-2/4 w-[1px]"></div> : <></>}
        <div className="flex w-full justify-center">{column.label}</div>
      </div>
    );
  };

  const renderCell = (
    rowindex: number,
    rowData: TableData,
    column: TableColumn,
  ) => {
    let leftBorder =
      columns[0].key !== column.key &&
        column.key !== columns[columns.length - 1].key
        ? ' border-l-2'
        : 'font-bold';

    let border =
      rowindex + 1 === data.length ? leftBorder : leftBorder + ' border-b-2 ';

    const bg =
      column.key !== columns[columns.length - 1].key
        ? ' bg-white'
        : ' border-gray-300 bg-gray-200';


    let text ='';
    if(rowData[column.key])
    {
      text = formatNumberWithCommas(rowData[column.key])
    }

    if((column.key !=='label') && (rowindex === 0 || rowindex === 2 || rowindex === 3))
    {
      if(text!=='')
        text  = '₩ ' + text;
    }

    if(column.key ==='label')
      {
        return (
          <div
            className={`${border} ${bg} flex items-center justify-center w-full  h-[39px]`}
          >
            {true ? <div className={'h-full w-[1px]'}></div> : <></>}
    
            {text}
          </div>
        );
      }
    

    return (
      <div
        className={`${border} ${bg} flex pr-4  items-center justify-end w-full  h-[39px]`}
      >
        {true ? <div className={'h-full w-[1px]'}></div> : <></>}

        {text}
      </div>
    );
  };


  
  

  return (
    <>
      <div className="flex text-[#474A4E]">
        <CBCVTab></CBCVTab>
        <div ref={printRef} className="FutureClientSegmentation w-full min-w-[1200px]">
          <TittleBar tittle="브랜드 고객 분석" />
          <div className="pt-[34px] flex justify-center item-cetner">
            <div className='w-[1113px] '>
              <div className='flex items-center justify-between'>
              <div className="text-wrapper">미래 고객 세그먼트</div>
           
             <SaveAsPDFButton printRef={printRef}></SaveAsPDFButton>
              </div>
    
              <div className="h-[45px]"></div>
              <TabComponent
                mainClassName="flex text-[#7B828B] gap-5"
                activeClassName="h-10 px-4 text-[#7B828B] "
                itemClassName="h-10 font-bold px-4 text-[#4046E3] border-b-4 border-[#4046E3] "
                tabs={[
                  { title: '전체', link: '/future-customers/all' },
                  { title: '쿠팡', link: '/future-customers/coupang' },
                  {
                    title: '스마트스토어',
                    link: '/future-customers/naver',
                  },
                  { title: '카페24', link: '/future-customers/cafe24' },
     /*              { title: '기타', link: '/future-customers/etc' }, */
                ]}
              ></TabComponent>
              <div className="h-[37px]"></div>
              <div className="border-l-4 pl-4 border-gray-800  justify-center text-wrapper-3">
                VVIP (상위 1%)
              </div>
              <div className="h-[16px]"></div>
              <div className="w-[800px] h-[88px] border-2 rounded-lg">
                <div className="bg-gray-100 flex h-1/2  w-full  border-gray-950">
                  <div className="flex  w-1/4 border-r-2 items-center justify-center">
                    <div className="font-bold">고객 수</div>
                  </div>
                  <div className="flex w-1/4 border-r-2 items-center justify-center">
                    <div className="font-bold">평균 과거 주문 금액</div>
                  </div>
                  <div className="flex w-1/4 border-r-2 items-center justify-center">
                    <div className="font-bold">평균 과거 주문 건 수</div>
                  </div>
                  <div className="flex w-1/4  items-center justify-center">
                    <div className="font-bold">과거 주문 금액 비중</div>
                  </div>
                </div>
                <div className="flex bg-white h-1/2  w-full  border-gray-100">
                  <div className="flex border-r-2  justify-end w-1/4 items-center pl-5 pr-5">
                    <div>{reportData.data.vvip_total_count}</div>
                  </div>
                  <div className="flex border-r-2  justify-end w-1/4 items-center pl-5 pr-5">
                    <div>{'₩ '+formatNumberWithCommas(reportData.data.vvip_avg_past_purchase_amount)}</div>
                  </div>
                  <div className="flex border-r-2  justify-end w-1/4 items-center pl-5 pr-5">
                    <div>{reportData.data.vvip_avg_past_purchase_count}</div>
                  </div>
                  <div className="flex  justify-end w-1/4 items-center pl-5 pr-5">
                    <div>
                    {(Number.parseFloat(reportData.data.vvip_past_purchase_portion)*100).batterToFix(2).toString() + '%'}
                     </div>
                  </div>
                </div>
              </div>
              <div className="h-[37px]"></div>
              <div className="border-l-4 pl-4 border-gray-800 text-wrapper-3">
                VIP (상위 10%)
              </div>{' '}
              <div className="h-[16px]"></div>
              <div className="w-[800px] h-[88px] border-2 rounded-lg">
                <div className="bg-gray-100 flex h-1/2  w-full  border-gray-950">
                  <div className="flex w-1/4 border-r-2 items-center justify-center">
                    <div className="font-bold">고객 수</div>
                  </div>
                  <div className="flex w-1/4 border-r-2 items-center justify-center">
                    <div className="font-bold">평균 과거 주문 금액</div>
                  </div>
                  <div className="flex w-1/4 border-r-2 items-center justify-center">
                    <div className="font-bold">평균 과거 주문 건 수</div>
                  </div>
                  <div className="flex w-1/4  items-center justify-center">
                    <div className="font-bold">과거 주문 금액 비중</div>
                  </div>
                </div>
                <div className="flex h-1/2 bg-white  w-full  border-gray-100">
                  <div className="flex border-r-2  justify-end w-1/4 items-center pl-5 pr-5">
                    <div>{reportData.data.vip_total_count}</div>
                  </div>
                  <div className="flex border-r-2  justify-end w-1/4 items-center pl-5 pr-5">
                    <div>{'₩ '+  formatNumberWithCommas(reportData.data.vip_avg_past_purchase_amount)}</div>
                  </div>
                  <div className="flex border-r-2  justify-end w-1/4 items-center pl-5 pr-5">
                    <div>{reportData.data.vip_avg_past_purchase_count}</div>
                  </div>
                  <div className="flex  justify-end w-1/4 items-center pl-5 pr-5">
                    <div>{(Number.parseFloat(reportData.data.vip_past_purchase_portion)*100).batterToFix(2).toString() + '%'}</div>
                  </div>
                </div>
              </div>
              <div className="h-[37px]"></div>
              <TableComponent
                columns={columns}
                data={data}
                className="text-sm border-2 rounded-lg"
                renderCell={renderCell}
                renderColumn={renderCol}
              />
            </div>
          </div>
        </div>{' '}
      </div>

    </>
  );
};
export default FutureClientSegmentation;
