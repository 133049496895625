/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

interface Props {
  className: any;
}

export const WSearchIcon = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={`w-search-icon ${className}`}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_3_121)">
        <mask className="mask" height="16" id="mask0_3_121" maskUnits="userSpaceOnUse" width="16" x="0" y="0">
          <path className="path" d="M16 0H0V16H16V0Z" fill="white" />
        </mask>
        <g className="g" mask="url(#mask0_3_121)">
          <path
            className="path"
            d="M6.798 11.6C9.44897 11.6 11.598 9.45097 11.598 6.8C11.598 4.14903 9.44897 2 6.798 2C4.14703 2 1.998 4.14903 1.998 6.8C1.998 9.45097 4.14703 11.6 6.798 11.6Z"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            className="path"
            d="M10.398 10.4L13.998 14"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </g>
      </g>
      <defs className="defs">
        <clipPath className="clipPath" id="clip0_3_121">
          <rect className="rect" fill="white" height="16" width="16" />
        </clipPath>
      </defs>
    </svg>
  );
};
