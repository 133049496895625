import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { ProfileModal } from './ProfileModal';
import useRESTAPI from './Contexts/RESTAPI';
import { useAuth } from './Contexts/AuthContext';

import {API_HOST} from "../config";
interface TittleBar {
  tittle: string;
}

const TittleBar: React.FC<TittleBar> = ({ tittle }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [comName, setComName] = useState<string>('');
  const modalRef = useRef<HTMLDivElement>(null);
  const { user_id } = useAuth()
  const {
    items,
    fetchItems,
    hasFetchedSuccessfully
  } = useRESTAPI(API_HOST + '/api/cbcv-request');

  const {
    items: user,
    fetchItem: fetchUser
  } = useRESTAPI(API_HOST + '/api/user');

  useEffect(() => {
    const _ = async () => {
      if (user_id) {
        const user = await fetchUser(user_id)
 
        setComName(user[0].company_name)
      }
    }
    _();
  }, [user_id]);
 

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      setIsModalOpen(false);
    }
  };
  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isModalOpen]);
  
  return (
    <div className="flex w-full justify-between items-center h-[57px] border-b-[1px]  border-gray-200 pr-5 ">
      <p className="pl-[23px] w-1/2 h-[32px] font-bold text-[#474a4e] text-[20px]">
        {tittle}
      </p>

      <div className="flex justify-end items-center  w-full h-[60px]">
        {hasFetchedSuccessfully && items.length === 0 && <div className="flex h-[30px] w-[157px] items-center justify-center gap-[6px] px-[8px] py-[3px]  bg-[#faecf1] rounded-[20px] overflow-hidden">
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.5 0C10.0823 0 11.629 0.469192 12.9446 1.34824C14.2602 2.22729 15.2855 3.47672 15.891 4.93853C16.4965 6.40034 16.655 8.00887 16.3463 9.56072C16.0376 11.1126 15.2757 12.538 14.1569 13.6569C13.038 14.7757 11.6126 15.5376 10.0607 15.8463C8.50887 16.155 6.90034 15.9965 5.43853 15.391C3.97672 14.7855 2.72729 13.7602 1.84824 12.4446C0.969192 11.129 0.5 9.58225 0.5 8C0.5 5.87827 1.34285 3.84344 2.84315 2.34315C4.34344 0.842855 6.37827 0 8.5 0Z"
              fill="#E53073"
            />
            <path d="M9.5 9H7.5V4H9.5V9Z" fill="white" />
            <path
              d="M8.5 11.999C8.23478 11.999 7.98043 11.8937 7.79289 11.7061C7.60536 11.5186 7.5 11.2642 7.5 10.999C7.5 10.7338 7.60536 10.4795 7.79289 10.2919C7.98043 10.1044 8.23478 9.99902 8.5 9.99902C8.76522 9.99902 9.01957 10.1044 9.20711 10.2919C9.39464 10.4795 9.5 10.7338 9.5 10.999C9.5 11.2642 9.39464 11.5186 9.20711 11.7061C9.01957 11.8937 8.76522 11.999 8.5 11.999Z"
              fill="white"
            />
          </svg>

          <Link to={'/connect-data'}>
            <div className="relative w-fit mt-[-1.00px] font-pretendard-bold-pretendard-14pt-bold font-[number:var(--pretendard-bold-pretendard-14pt-bold-font-weight)] text-sub-colorred-0 text-[length:var(--pretendard-bold-pretendard-14pt-bold-font-size)] tracking-[var(--pretendard-bold-pretendard-14pt-bold-letter-spacing)] leading-[var(--pretendard-bold-pretendard-14pt-bold-line-height)] whitespace-nowrap [font-style:var(--pretendard-bold-pretendard-14pt-bold-font-style)]">
              데이터 연동 필요
            </div>
          </Link>
        </div>}
        {isModalOpen && (
          <div ref={modalRef}>
            <ProfileModal />
          </div>
        )}
        <button
          onClick={toggleModal}
          className="ml-5 inline-flex items-center justify-end gap-[6px] px-[8px] py-[6px] bg-[#F2F4F7] rounded-[6px] overflow-hidden"
        >
          {user_id &&           <div className="w-[30px] h-[28px]">
            <div className="flex justify-center items-center w-[28px] h-[28px] bg-[#83b8ff] rounded-[14px] text-white">
            {comName[0]}
            </div>
          </div>}

          {user_id &&  <div className="w-fit font-pretendard-medium-pretendard-14pt-medium font-[number:var(--pretendard-medium-pretendard-14pt-medium-font-weight)] text-[#474a4e] text-[length:var(--pretendard-medium-pretendard-14pt-medium-font-size)] text-right tracking-[var(--pretendard-medium-pretendard-14pt-medium-letter-spacing)] leading-[var(--pretendard-medium-pretendard-14pt-medium-line-height)] whitespace-nowrap [font-style:var(--pretendard-medium-pretendard-14pt-medium-font-style)]">
            {comName}
          </div>}
        </button>
      </div>
    </div>
  );
};

export default TittleBar;
