import React, { useEffect, useState } from 'react';
import Button from '../Button';
import './ConnectData.css';
import ModalBase from '../common/ModalBase';
import { ModalProvider, useModal } from '../modal/ModalContext';
import CURegiModal from '../modals/CURegiModal';
import Cafe24Modal from '../modals/Cafe24Modal';
import SSRegiModal from '../modals/SSRegiModal';
import useRESTAPI from '../Contexts/RESTAPI';
import { AuthProvider, useAuth } from '../Contexts/AuthContext';
import { FaFileUpload } from 'react-icons/fa';
import FileUploadModal from '../modals/FileUploadModal';
import CBCVModalMessageProvider from '../common/CBCVModalMessageProvider';
import { formatDate, formatDateTime } from '../common/Auxiliary';
import { Link } from 'react-router-dom';
import TittleBar from '../TittleBar';
import CBCVTab from '../../CBCVTab';
import {API_HOST} from "../../config";

interface CBCVRequest {
  created?: Date;
  updated?: Date;
  request_id?: number;
  status: number;
  notified: boolean;
  cbcv_user_id: number;
}

interface ApiKeys {
  id?: number;
  cbcv_user_id: number;
  api_keys: string;
  api_kind: string;
  api_verified: string;
}

export const ConnectData = (): JSX.Element => {
  const { hideModal, showModal } = useModal();
  const { user_id } = useAuth();
  const [page, setpage] = useState<boolean>(false);
  const [requestState, setRequestState] = useState<
    'none' | 'requested' | 'complete'
  >('requested');

  useEffect(() => {
    const fetchData = async () => {
      await fetchRequests({ cbcv_user_id: user_id });
      await fetchKeys({ cbcv_user_id: user_id });
      await fetchFiles({ cbcv_user_id: user_id })
      await fetchAccounting({ cbcv_user_id: user_id, pageSize: 999999 })
    };
    if (user_id) {
      fetchData();
    }
  }, [user_id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchRequests({ cbcv_user_id: user_id });
        await fetchKeys({ cbcv_user_id: user_id });
        await fetchFiles({ cbcv_user_id: user_id })
        await fetchAccounting({ cbcv_user_id: user_id, pageSize: 999999 })

      } catch (error) { }

      fetchData();
    };
  }, []);


  const { items: accountingData, editItem:editAccounting, fetchItems: fetchAccounting } = useRESTAPI(API_HOST + '/api/analysis/accountingdata');


  useEffect(() => {
  }, [accountingData]);


  const {
    fetchItems: fetchFiles,
    items: files,/*
  fetchItems: fetchRequests,
  addItem: addRequest,
  editItem: editRequest,
  removeItem:removeRequest,
  hasFetchedSuccessfully: requestFeched, */
  } = useRESTAPI(API_HOST + '/api/extraFile');






  const {
    items: requests,
    fetchItems: fetchRequests,
    addItem: addRequest,
    editItem: editRequest,
    hasFetchedSuccessfully: requestFeched,
  } = useRESTAPI(API_HOST + '/api/cbcv-request');

  const {
    items: keys,
    fetchItems: fetchKeys,
    hasFetchedSuccessfully: keyfetched,
  } = useRESTAPI(API_HOST + '/api/api-keys');

  useEffect(() => {
    const lastObject = requests[requests.length - 1];

    if (requests.length === 0 || lastObject.status > 4) {
      setRequestState('none');
    }
    else {
      if (lastObject.status > 4) {
        setRequestState('complete');
      }
      else {
        setRequestState('requested');
      }
    }

  }, [requests]);

  useEffect(() => { }, [requestState]);

  useEffect(() => { }, [requests, keys, user_id]);

  const whenHideModal = async () => {
    await fetchKeys({ cbcv_user_id: user_id });
    await fetchFiles({ cbcv_user_id: user_id });
    await hideModal();
  };
  const sendRequest = async (data: any) => {
 
    const newRequst = await addRequest(data);

/*     for (let index = 0; index < accountingData.length; index++) {
      console.log(accountingData[index].id);
      await editAccounting(accountingData[index].id ,{ request_id: newRequst.request_id})
    } */


    hideModal();
    setpage(false);

  }
  const requestAnalysis = async () => {
    const data: CBCVRequest = {
      cbcv_user_id: user_id || -1,
      notified: false,
      status: 0,
    };

    if (requestState === 'requested') {


      showModal(
        <ModalBase>
          <div className="flex flex-col text-gray-600 bg-white p-2 rounded-lg w-[341px] h-[229px] items-center justify-center">
            <div className="text-lg font-bold mb-4">
            <p>현재 진행중인 분석요청이 있습니다.</p>
            <p> 그래도 분석을 요청하시겠습니까?</p>
            </div>
            <div className='flex gap-2' >
              <button
                onClick={() => sendRequest(data)}
                type="submit"
                style={{ background: '#4046E3' }}
                className="text-white font-bold w-[164px] h-[36px] py-1 px-2 rounded-md focus:outline-none focus:shadow-outline"
              >
                분석 요청
              </button>
              <button
                onClick={hideModal}
                type="submit"
                style={{ background: '#7D7D7D' }}
                className="text-white font-bold w-[82px] h-[36px] py-1 px-2 rounded-md focus:outline-none focus:shadow-outline"
              >  취소</button>
            
            </div>
          </div>
        </ModalBase>
      )


    }
    else {
      await addRequest(data);
    }

    setpage(false);
  };

  const smartStore = () => {
    showModal(
      <AuthProvider>
        <SSRegiModal onClose={whenHideModal} />
      </AuthProvider>,
    );
  };
  const cafe24 = () => {
    showModal(
      <CBCVModalMessageProvider>
        <AuthProvider>
          <Cafe24Modal onClose={whenHideModal} />
        </AuthProvider>
      </CBCVModalMessageProvider>,
    );
  };
  const coupang = () => {
    showModal(
      <AuthProvider>
        <CURegiModal onClose={whenHideModal} />
      </AuthProvider>,
    );
  };

  const fileUpload = () => {
    showModal(
      <AuthProvider>
        <FileUploadModal onClose={whenHideModal} />
      </AuthProvider>,
    );
  };

  const renderCoupang = () => {
    let hasRequest = requestState === 'none';

    let done = false;
    try {
      done = requestState === 'complete'
    } catch (error) {
      done = false;
    }

    const hasKey = keys.find((key) => key.api_kind === 'coupang');

    let updateDate = '';
    if (hasKey) {
      updateDate =
        formatDate(keys.find((key) => key.api_kind === 'coupang').updatedAt) +
        ' 업데이트';
    }

    if (!hasKey && hasRequest) {
      hasRequest = false;
    }

    return (
      <div className="rectangle-4">
        <img className="w-[130px]" alt="Coupang logo" src="cp.png" />
        {!hasKey && (
          <Button
            className="button-instance"
            property1="variant-3"
            text="연결하기"
            onClick={coupang}
          />
        )}
        {hasKey && (
          <div className="py-2 font-medium text-[#64686e] text-[12px]">
            {updateDate}
          </div>
        )}
        {requestState === 'none' && hasKey && (
          <button className="bg-[#D8DDE3] w-[215px] h-[39px] rounded-lg">
            연결됨
          </button>
        )}
        {requestState === 'requested' && (
          <button className="h-[39px] w-[215px] flex rounded-lg items-center justify-center text-[#4046E3] bg-[#EAEBFA]">
            {refreshicon()}분석 중
          </button>
        )}
        {done && (
          <button className="h-[39px] w-[215px] flex rounded-lg items-center justify-center text-[#33363B] bg-[#D8DDE3]">
            분석 완료
          </button>
        )}

        {requestState === 'none' && hasKey && (
          <div
            onClick={coupang}
            className="pt-2 font-medium text-[#424242] text-[12px] tracking-[-0.20px] leading-[20px] underline whitespace-nowrap"
          >
            재연결 하기
          </div>
        )}
        {requestState === 'complete' && (
          <div className="pt-2 font-medium text-[#bebebe] text-[12px] tracking-[-0.20px] leading-[20px] underline whitespace-nowrap">
            재연결 하기
          </div>
        )}
      </div>
    );
  };

  const refreshicon = () => {
    return (
      <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_894_275)">
          <mask
            id="mask0_894_275"
            maskUnits="userSpaceOnUse"
            x="1"
            y="1"
            width="15"
            height="15"
          >
            <path d="M15.501 1H1.5V15.001H15.501V1Z" fill="white" />
          </mask>
          <g mask="url(#mask0_894_275)">
            <path
              d="M13.4749 9.95068C13.0446 11.021 12.2772 11.9219 11.2889 12.517C10.3007 13.112 9.14546 13.3688 7.99819 13.2485C6.85092 13.1281 5.77413 12.6371 4.93092 11.8499C4.08771 11.0627 3.52402 10.0221 3.32523 8.88581C3.12643 7.7495 3.30337 6.57936 3.82923 5.55263C4.3551 4.5259 5.20125 3.69851 6.23951 3.19579C7.27777 2.69307 8.45158 2.54241 9.58315 2.76662C10.7147 2.99083 11.7424 3.57771 12.5105 4.43835"
              stroke="#4046E3"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.7795 5.25003L13.2261 5.40782L13.3839 2.96113"
              stroke="#4046E3"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_894_275">
            <rect
              width="16"
              height="16"
              fill="white"
              transform="translate(0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  };

  const renderSS = () => {
    let hasRequest = requestState === 'none';
    let done = false;
    done = requestState === 'complete';

    const hasKey = keys.find((key) => key.api_kind === 'naver');
    let updateDate = '';
    if (hasKey) {
      updateDate =
        formatDate(keys.find((key) => key.api_kind === 'naver').updatedAt) +
        ' 업데이트';
    }
    if (!hasKey && hasRequest) {
      hasRequest = false;
    }
    return (
      <div className="rectangle-4">
        <img className="w-[130px]" alt="Element" src="ss.png" />
        {!hasKey && (
          <Button
            className="button-instance"
            property1="variant-3"
            text="연결하기"
            onClick={smartStore}
          />
        )}
        {hasKey && (
          <div className="py-2 font-medium text-[#64686e] text-[12px]">
            {updateDate}
          </div>
        )}
        {requestState === 'none' && hasKey && (
          <button className="bg-[#D8DDE3] w-[215px] h-[39px] rounded-lg">
            연결됨
          </button>
        )}
        {requestState === 'requested' && (
          <button className="h-[39px] w-[215px] flex rounded-lg items-center justify-center text-[#4046E3] bg-[#EAEBFA]">
            {refreshicon()}분석 중
          </button>
        )}

        {requestState === 'none' && hasKey && (
          <div
            onClick={smartStore}
            className="pt-2 font-medium text-[#424242] text-[12px] tracking-[-0.20px] leading-[20px] underline whitespace-nowrap"
          >
            재연결 하기
          </div>
        )}
        {done && (
          <button className="h-[39px] w-[215px] flex rounded-lg items-center justify-center text-[#33363B] bg-[#D8DDE3]">
            분석 완료
          </button>
        )}
        {requestState === 'complete' && (
          <div className="pt-2 font-medium text-[#bebebe] text-[12px] tracking-[-0.20px] leading-[20px] underline whitespace-nowrap">
            재연결 하기
          </div>
        )}
      </div>
    );
  };

  const renderCafe24 = () => {
    let hasRequest = requestState === 'none';
    let done;
    done = requestState === 'complete';
    const hasKey = keys.find((key) => key.api_kind === 'cafe24');
    let updateDate = '';
    if (hasKey) {
      updateDate =
        formatDate(keys.find((key) => key.api_kind === 'cafe24').updatedAt) +
        ' 업데이트';
    }
    if (!hasKey && hasRequest) {
      hasRequest = false;
    }
    return (
      <div className="rectangle-4">
        <img className="w-[130px]" alt="Element" src="cafe24.png" />
        {!hasKey && (
          <Button
            className="button-instance"
            property1="variant-3"
            text="연결하기"
            onClick={cafe24}
          />
        )}
        {hasKey && (
          <div className="py-2 font-medium text-[#64686e] text-[12px]">
            {updateDate}
          </div>
        )}
        {requestState === 'none' && hasKey && (
          <button className="bg-[#D8DDE3] w-[215px] h-[39px] rounded-lg">
            연결됨
          </button>
        )}
        {requestState === 'requested' && (
          <button className="h-[39px] w-[215px] flex rounded-lg items-center justify-center text-[#4046E3] bg-[#EAEBFA]">
            {refreshicon()}분석 중
          </button>
        )}
        {done && (
          <button className="h-[39px] w-[215px] flex rounded-lg items-center justify-center text-[#33363B] bg-[#D8DDE3]">
            분석 완료
          </button>
        )}
        {requestState === 'none' && hasKey && (
          <div
            onClick={cafe24}
            className="pt-2 font-medium text-[#424242] text-[12px] tracking-[-0.20px] leading-[20px] underline whitespace-nowrap"
          >
            재연결 하기
          </div>
        )}
        {requestState === 'complete' && (
          <div className="pt-2 font-medium text-[#bebebe] text-[12px] tracking-[-0.20px] leading-[20px] underline whitespace-nowrap">
            재연결 하기
          </div>
        )}
      </div>
    );
  };

  const renderFileUpload = () => {

    let hasKey = false;

    if ('length' in files && files.length > 0) {
      hasKey = true;
    }


    let updateDate = '';
    if (hasKey) {
      updateDate =
        formatDate(files[0].updatedAt) +
        ' 업데이트';
    }

    if (requestState === 'requested') {
      return <></>;
    }

    return (
      <div className="rectangle-4">
        <div className="text-wrapper-8">+ 추가 파일 업로드</div>
        {hasKey && (
          <div className="py-2 font-medium text-[#64686e] text-[12px]">
            {updateDate}
          </div>
        )}
        {requestState === 'none' && hasKey && (
          <button className="bg-[#D8DDE3] w-[215px] h-[39px] rounded-lg">
            연결됨
          </button>
        )}
        {!hasKey && (
          <Button
            onClick={fileUpload}
            className="button-instance"
            property1="variant-3"
            text="업로드하기"
          />
        )}
        {/*         {requestState === 'none' && (
          <button className="h-[39px] w-[215px] flex rounded-lg items-center justify-center text-[#4046E3] bg-[#EAEBFA]">
            {refreshicon()}분석 중
          </button>
        )} */}

        {requestState === 'none' && hasKey && (
          <div
            onClick={fileUpload}
            className="pt-2 font-medium text-[#424242] text-[12px] tracking-[-0.20px] leading-[20px] underline whitespace-nowrap"
          >
            재연결 하기
          </div>
        )}
        {requestState === 'complete' && (
          <div className="pt-2 font-medium text-[#bebebe] text-[12px] tracking-[-0.20px] leading-[20px] underline whitespace-nowrap">
            재연결 하기
          </div>
        )}
      </div>
    );
  };

  const tab2 = () => {
    console.log('tab2');
    return (
      <>
        <div className="flex gap-2">
          <div className="text-wrapper-7">비용 데이터 연동하기</div>
          <div className="text-wrapper-8">*선택</div>
        </div>

        <div className="h-5"></div>
        <p className="p">
          매출, 매출원가, 판매비와 관리비에 대한 비용 데이터를 입력하여 분석의
          정확도를 높이세요.
          <br />
          추후 다시 입력하실 수 있습니다.
        </p>
        <div className="h-10"></div>
        {accountingData.length > 0 && <div className="text-sm text-gray-600">{formatDateTime(accountingData[0].creation_datetime).replaceAll('-','.')}  업데이트</div>}
        <div className="h-3"></div>
        {requestState === 'none' && (
          <Link to={'/cost-data-page'}>
            <Button
              className="button-instance"
              property1="variant-3"
              text="비용 데이터 입력하기"
            />
          </Link>
        )}
        {requestState === 'requested' && (
          <button className="h-[50px] w-[215px] flex rounded-lg items-center justify-center text-[#4046E3] bg-[#EAEBFA]">
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_894_275)">
                <mask
                  id="mask0_894_275"
                  maskUnits="userSpaceOnUse"
                  x="1"
                  y="1"
                  width="15"
                  height="15"
                >
                  <path d="M15.501 1H1.5V15.001H15.501V1Z" fill="white" />
                </mask>
                <g mask="url(#mask0_894_275)">
                  <path
                    d="M13.4749 9.95068C13.0446 11.021 12.2772 11.9219 11.2889 12.517C10.3007 13.112 9.14546 13.3688 7.99819 13.2485C6.85092 13.1281 5.77413 12.6371 4.93092 11.8499C4.08771 11.0627 3.52402 10.0221 3.32523 8.88581C3.12643 7.7495 3.30337 6.57936 3.82923 5.55263C4.3551 4.5259 5.20125 3.69851 6.23951 3.19579C7.27777 2.69307 8.45158 2.54241 9.58315 2.76662C10.7147 2.99083 11.7424 3.57771 12.5105 4.43835"
                    stroke="#4046E3"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10.7795 5.25003L13.2261 5.40782L13.3839 2.96113"
                    stroke="#4046E3"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </g>
              <defs>
                <clipPath id="clip0_894_275">
                  <rect
                    width="16"
                    height="16"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
            분석 중
          </button>
        )}
        {requestState === 'complete' && (
          <button className="h-[50px] w-[215px] flex rounded-lg items-center justify-center text-[#33363B] bg-[#D8DDE3]">
            분석 완료
          </button>
        )}

        {requestState === 'requested' && (
          <Link to={'/cost-data-page'}>
            <button className="pt-2 font-medium text-[#424242] text-[12px] tracking-[-0.20px] leading-[20px] underline whitespace-nowrap">
              비용 데이터 수정하기
            </button>
          </Link>
        )}
        {requestState === 'requested' && (
          <div className="flex mt-72 bg-[#faecf1] justify-center items-center w-[817px] h-[85px] p-4 ">
            <div className=" text-[#ce326c]   rounded-md">
              분석 보고서 조회까지 평균 5영업일 소요되며, 데이터가 많을 시
              추가적인 소요시간이 발생할 수 있습니다. 분석 완료 시 메일로
              안내드립니다.
            </div>
          </div>
        )}
      </>
    );
  };

  const tab1 = () => {
    return (
      <>
        <div className="h-9"></div>
        <div className="flex gap-2">
          <div className="text-wrapper-6">주문 데이터 연동하기</div>
          <div className="text-wrapper-9">*최소 1개 필수</div>
        </div>
        <div className="h-5"></div>

        <p className="p">
          주문 데이터를 연동하여 내 브랜드의 가치, 미래 예상 매출, 고객 분석,
          상품 분석등을 확인해 보세요!
          <br />
          모든 연동 데이터는 안전하게 보관 됩니다.
        </p>
        <div className="h-9"></div>
        <div>
          <div className="rectangle-3">
            {renderCoupang()}
            {renderSS()}
            {renderCafe24()}
            {renderFileUpload()}
          </div>
          {requestState === 'requested' && (
            <div className="flex mt-36 rounded-lg bg-[#faecf1] justify-center items-center w-[817px] h-[85px] p-4 ">
              <div className=" text-[#ce326c]   rounded-md">
                분석 보고서 조회까지 평균 5영업일 소요되며, 데이터가 많을 시
                추가적인 소요시간이 발생할 수 있습니다. 분석 완료 시 메일로
                안내드립니다.
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

  function checkIcon() {
    return (<svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
        fill="#A7A7A7"
      />
      <mask
        id="mask0_58_2150"
        maskUnits="userSpaceOnUse"
        x="4"
        y="4"
        width="12"
        height="12"
      >
        <path d="M16 4H4V16H16V4Z" fill="white" />
      </mask>
      <g mask="url(#mask0_58_2150)">
        <path
          d="M5.66089 10.001L8.55389 12.893L14.3389 7.10797"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>)
  }

  function renderBottomBar(): React.ReactNode {
    var buttons = <></>;

    const anyKey = keys.length !== 0;

    let done;
    try {
      done = requests[0].status === 4;
    } catch (error) {
      done = false;
    }


    if (!page && !anyKey) {
      buttons = (
        <button
          onClick={() => setpage(keys.length > 0)}
          className={
            'bg-[#BCC2C9] h-[44px] w-[96px] rounded-md text-white flex items-center justify-center'
          }
        >
          다음
        </button>
      );
    } else if (!page && anyKey) {
      buttons = (
        <button
          onClick={() => setpage(keys.length > 0)}
          className={
            'bg-[#4046E3] h-[44px] w-[96px] rounded-md text-white flex items-center justify-center'
          }
        >
          다음
        </button>
      );
    } else if (page && anyKey) {
      buttons = (
        <div className="flex gap-2">
          <button
            onClick={() => setpage(false)}
            className={`border-2 h-[44px] w-[96px] rounded-md  flex items-center justify-center`}
          >
            이전
          </button>

          <button
            onClick={() => requestAnalysis()}
            className={
              'bg-[#4046E3] h-[44px] w-[96px] rounded-md text-white flex items-center justify-center'
            }
          >
            분석 요청
          </button>



        </div>
      );
    }

    return (
      <div className="border-t-2 border-gray-400 rectangle-8 gap-3">
        {buttons}
      </div>
    );
  }

  return (
    <div className="flex text-[#474A4E]">
      <CBCVTab></CBCVTab>
      <div className="w-full">
        <TittleBar tittle={'기본 설정 - 데이터 연동'} />
        <div className="bg-[#f9fafb] flex justify-center items-center">
          <div className="ConnectData">
            <div className="w-[920px] overlap-wrapper">
              <div className="text-wrapper-11 ">분석 데이터 연결</div>
              <div className="overlap">
                <div className="flex">
                  <div className={page ? `group-5` : `group-2`}>
                    <div className="w-5 h-5">
                      {checkIcon()}
                    </div>
                    <div className="text-center text-wrapper-5">
                      주문 데이터
                    </div>
                  </div>
                  <div className={!page ? `group-5` : `group-2`}>
                    <div className="w-5 h-5">
                      {checkIcon()}
                    </div>

                    <div className="text-center text-wrapper-5">
                      비용 데이터
                    </div>
                  </div>
                </div>
                {user_id !== undefined && requestFeched && keyfetched && (
                  <div className="overlap-2">
                    <div className="p-7">{page ? tab2() : tab1()}</div>
                    {renderBottomBar()}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="h-10 w-10"></div>
        </div>
      </div>
    </div>
  );
};
export default ConnectData;
