import React, { useRef } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

interface SaveAsPDFButtonProps {
  printRef: React.RefObject<HTMLDivElement>;
}

const SaveAsPDFButton: React.FC<SaveAsPDFButtonProps> = ({ printRef }) => {
  
  const handlePrint = async () => {
    if (!printRef.current) {
      return;
    }

    const element = printRef.current;
    const canvas = await html2canvas(element);
   // const imgData = canvas.toDataURL('image/png');
    const imgData = canvas.toDataURL('image/jpeg'); 

    const elementWidth = element.offsetWidth;
    const elementHeight = element.offsetHeight;

    const pdf = new jsPDF({
      orientation: elementWidth > elementHeight ? 'landscape' : 'portrait',
      unit: 'px',
      format: [elementWidth, elementHeight]
    });

    pdf.addImage(imgData, 'JPG', 0, 0, elementWidth, elementHeight);
    pdf.save('OMOVALUE_REPORT.pdf');
  };

  return (
    <div onClick={() => handlePrint()} className="cursor-pointer flex gap-1 pl-5 pt-2 pb-2 pr-5 justify-center items-center rounded-lg border-2 bg-white">
      <div className="w-5 h-5">
        <svg
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_69_9166"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="16"
            height="16"
          >
            <path d="M16 0H0V16H16V0Z" fill="white" />
          </mask>
          <g mask="url(#mask0_69_9166)">
            <path
              d="M13 5V11H6V2H10L13 5Z"
              stroke="#474A4E"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6 5H3V14H10V11"
              stroke="#474A4E"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      </div>
      <div>PDF 저장하기</div>
    </div>
  );
};

export default SaveAsPDFButton;
