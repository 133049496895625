
import PropTypes from "prop-types";
import React from "react";
import { BSearchIocn } from "./icons/BSearchIocn";
import { WSearchIcon } from "./icons/WSearchIcon";
import "./Button.css";

interface Props {
  property1: "variant-4" | "variant-3" | "icon" | "default";
  className: any;
  text?: string;
  visible?: boolean;
  onClick?: () => void;  
}

export  const Button = ({ property1, className, text = "Text", visible = true ,onClick}: Props): JSX.Element => {
  return (
    <button onClick={onClick} className={`cursor-pointer button ${property1} ${className}`}>
      {["default", "variant-4"].includes(property1) && (
        <>
          <>
            {visible && (
              <>
                <>
                  {property1 === "variant-4" && <BSearchIocn className="instance-node" />}

                  {property1 === "default" && <WSearchIcon className="instance-node" />}
                </>
              </>
            )}
          </>
        </>
      )}

      <div className="text">{text}</div>
    </button>
  );
};

Button.propTypes = {
  property1: PropTypes.oneOf(["variant-4", "variant-3", "icon", "default"]),
  text: PropTypes.string,
  visible: PropTypes.bool,
};
export default Button; 