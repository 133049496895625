import React from 'react';
import './App.css';
import Footer from './components/common/Footer';
import { AppProvider, useAppContext } from './AppContext';
import { AuthProvider } from './components/Contexts/AuthContext';

import {
  BrowserRouter,
  Routes,
  Route,
  Router,
  useLocation,
} from 'react-router-dom';

import { CBCVTab } from './CBCVTab';
import SignUp from './components/pages/SignUp';
import NewPassword from './components/pages/NewPassword';
import CustomerValueStatus from './components/pages/CustomerValueStatus';
import { ScenarioBasedRevenueForecast } from './components/pages/ScenarioBasedRevenueForecast';
import FutureRevenueForecast from './components/pages/FutureRevenueForecast';
import CustomerStatus from './components/pages/CustomerStatus';
import FutureClientSegmentation from './components/pages/FutureClientSegmentation';
import PastOneYearSegmentation from './components/pages/PastOneYearSegmentation';
import LogIn from './components/pages/Login';
import AdminLogin from './components/pages/AdminLogin';
import ManageRequests from './components/pages/ManageRequests';
// import LandingPage from './components/pages/LandingPage';
import LandingPage from './components/pages/Main';
import FindMyID from './components/pages/FindMyID';
import { ModalProvider } from './components/modal/ModalContext';
import CBCVModalMessageProvider from './components/common/CBCVModalMessageProvider';
import RequireAuth from './components/common/RequireAuth';
import ConnectData from './components/pages/ConnectData';
import TittleBar from './components/TittleBar';
import CostDataPage from './components/pages/CostData';
import CBCVAdminTab from './CBCVAdminTab';
import HighRepurchaseProducts from './components/pages/HighRepurchaseProducts';
import LossLeaderProduct from './components/pages/LossLeaderProduct';
import SeasonalProducts from './components/pages/SeasonalProducts';
import CustomerList from './components/pages/CustomerList';
import ProductList from './components/pages/ProductList';
import RequireAdminAuth from './components/common/RequireAdminAuth';
import Test from './components/Test';
import TermsOfUse from './components/pages/TermsOfUse';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import { WorngPassword } from './components/modals/WorngPassword';

function App() {
  document.title = 'OMOVALUE'

  return (
    <AppProvider>
      {/*전역 모달*/}
      <ModalProvider>
        {/* */}
        <CBCVModalMessageProvider>
          {/* 로그인*/}
          <AuthProvider>
    
            <BrowserRouter>
              <Routes>
                {/*       <Route path="/test" element={<Test />} /> */}
                <Route path="/" element={<LandingPage />} />
                <Route path="/login" element={<LogIn />} />
                <Route path="/findmyid" element={<FindMyID />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/adminlogin" element={<AdminLogin />} />
                <Route path="/admin" element={<RequireAdminAuth><ManageRequests /></RequireAdminAuth>} />
                <Route
                  path="/admin"
                  element={<ManageRequests></ManageRequests>}
                />
                <Route
                  path="/connect-data"
                  element={
                    <RequireAuth>
                      <ConnectData />
                    </RequireAuth>
                  }
                />
                <Route 
                  path="/cost-data-page"
                  element={
                    <RequireAuth>
                      <CostDataPage />
                    </RequireAuth>
                  }
                />

                <Route
                  path="/customer-value-status"
                  element={
                    <CustomerValueStatus />
                  }
                />
                <Route
                  path="/future-revenue-forecast"
                  element={
                    <FutureRevenueForecast />
                  }
                />


                <Route
                  path="/scenario-based-revenue-forecast"
                  element={
                    <ScenarioBasedRevenueForecast />
                  }
                />
                <Route
                  path="/customer-status"
                  element={
                    <CustomerStatus />
                  }
                />
                <Route
                  path="/customer-status/:tab"
                  element={

                    <CustomerStatus />

                  }
                />
                <Route
                  path="/future-customers"
                  element={
                    <FutureClientSegmentation />
                  }
                />
                <Route
                  path="/future-customers/:tab"
                  element={

                    <FutureClientSegmentation />

                  }
                />
                <Route
                  path="/customers-last-year"
                  element={
                    <PastOneYearSegmentation />
                  }
                />
                <Route
                  path="/customers-last-year/:tab"
                  element={
                    <PastOneYearSegmentation />
                  }
                />
                <Route
                  path="/high-repurchase-products"
                  element={<HighRepurchaseProducts></HighRepurchaseProducts>}
                />
                <Route
                  path="/high-repurchase-products/:tab"
                  element={<HighRepurchaseProducts></HighRepurchaseProducts>}
                />
                <Route
                  path="/priming-products"
                  element={<LossLeaderProduct />}
                />
                <Route
                  path="/priming-products/:tab"
                  element={<LossLeaderProduct />}
                />
                <Route
                  path="/seasonal-products"
                  element={<SeasonalProducts />}
                />
                <Route
                  path="/seasonal-products/:tab"
                  element={<SeasonalProducts />}
                />
                <Route path="/product-list" element={<ProductList />} />
                <Route path="/product-list/:tab" element={<ProductList />} />
                <Route path="/customer-list" element={<CustomerList />} />
                <Route path="/customer-list/:tab" element={<CustomerList />} />
                <Route path="/newpassword/:uuid" element={<NewPassword />} />
                <Route path="/adminlogin" element={<AdminLogin />} />
                <Route path="/termsofuse" element={<TermsOfUse />} />
                <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              

       
              </Routes>
            </BrowserRouter>
          </AuthProvider>
        </CBCVModalMessageProvider>
      </ModalProvider>
    </AppProvider>
  );
}

export default App;
