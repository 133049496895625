import React, { useState, useEffect } from 'react';
import { Button } from '../Button';
import './Cafe24Modal.css';
import ModalBase from '../common/ModalBase';
import { useModalMessage } from '../common/CBCVModalMessageProvider';
import useRESTAPI from '../Contexts/RESTAPI';
import { useAuth } from '../Contexts/AuthContext';
import {API_HOST} from "../../config";

interface CURegiModalProps {
  onClose: () => void;
}

export const Cafe24Modal = ({ onClose }: CURegiModalProps): JSX.Element => {
  const handleClose = () => {
    onClose();
  };
  const { user_id } = useAuth();
  const [mallID, setMallID] = useState<string>();
  const [mallIDError, setMallIDError] = useState<string>();
  const [idConfirmed, setIdConfirmed] = useState<boolean>(false);
  const { updateMessage } = useModalMessage();

  const { items, fetchItems, addItem, editItem, removeItem } =
    useRESTAPI(API_HOST + '/api/api-keys');

  useEffect(() => {
    setMallIDError(undefined);
  }, [mallID]);

  const checkData = () => {
    const regex = /^[a-z0-9]{4,16}$/;
    if (!regex.test(mallID || '')) {
      setMallIDError('아이디는 4~16자리, 영소/숫자이어야 합니다.');
    } else {
      const url = `https://${mallID}.cafe24api.com/api/v2/oauth/authorize?client_id=YJenfgIFykWie5HEpQm1qL&redirect_uri=https://auth.headless.co.kr/auth/oauth/callback&response_type=code&state=${mallID}&scope=mall.read_application,mall.read_category,mall.write_category,mall.read_product,mall.write_product,mall.read_collection,mall.write_collection,mall.read_supply,mall.write_supply,mall.read_order,mall.write_order,mall.read_customer,mall.write_customer,mall.read_store,mall.write_store,mall.read_promotion,mall.write_promotion,mall.read_analytics,mall.read_privacy,mall.read_personal`;
      window.open(url, '_blank', 'noopener,noreferrer');
      handlePostRequest();
    }
  };

  const handlePostRequest = async () => {
    const target = await fetchItems({
      cbcv_user_id: user_id,
      api_kind: 'cafe24',
    });

    if (target.length) await removeItem(target[0].id);

    await addItem({
      cbcv_user_id: user_id,
      api_kind: 'cafe24',
      api_verified: false,
      cafe24_mall_id: mallID,
    });
    setIdConfirmed(true);
  };

  const connectData = async () => {
    const result = await fetch(
      `${API_HOST}/api/cbcv-api/auth/oauth/access?mallKey=${mallID}`,
    );

    if (result.status === 200) {
      const data = await fetchItems({
        cbcv_user_id: user_id,
        api_kind: 'cafe24',
      });
      editItem(data[0].id, {
        api_verified: true,
      });

      onClose();
    } else {
      updateMessage('error', '연동이 실패되었습니다.');
    }
  };

  return (
    <ModalBase>
      <div className="flex  rounded-lg flex-col justify-between Cafe24Modal w-[664px] h-[586px] bg-white">
        <div className="p-[15px] div rounded-t-md">
          <div className="p-2">
            <div className="text-wrapper-14">카페24 주문 데이터 연동</div>
            <div className="h-4"></div>
            <hr></hr>
            <div className="h-4"></div>
            <div className="div2">
              <div className="">
                Mall ID (카페24 로그인시 사용하는 계정 ID){' '}
              </div>
              {idConfirmed && (
                <div className="bg-[#BCC2C9] border  rounded-md flex items-center h-10 w-full">
                  <div className="pl-7 text-gray-500">
                    Mall ID를 입력해 주세요.
                  </div>
                </div>
              )}
              {!idConfirmed && (
                <input
                  value={mallID}
                  onChange={(e) => setMallID(e.target.value)}
                  placeholder={mallIDError || 'Mall ID를 입력해 주세요.'}
                  type="text"
                  className={`bg-white border ${mallIDError ? 'border-[#E53073] placeholder-[#E53073]' : 'border-[#d8dde3]'} rounded-[5px] flex items-center pl-[25px] h-[38px] w-full text-left ${mallIDError ? 'text-[#E53073]' : 'text-gray-500'}`}
                />
              )}
              <div className="h-4"></div>
              <div className="flex justify-center items-center">
                {idConfirmed && (
                  <div className="bg-[#BCC2C9] border  rounded-md flex justify-center items-center h-10 w-36">
                    <div className="  text-gray-500">연동 완료</div>
                  </div>
                )}
                {!idConfirmed && (
                  <button
                    onClick={checkData}
                    className="text-[#474A4E] w-[149px] h-[40px] border-[1px] border-gray-300 rounded-lg bg-white flex items-center justify-center "
                  >
                    계정 연동
                  </button>
                )}
              </div>
            </div>
            <div className="p-8">
              <div className="text-wrapper-16">카페24 연동 가이드</div>
              <div className="h-2"> </div>
              <div className="w-[493px]">
                <p className="font-medium text-[#474a4e] text-[12px] tracking-[0] leading-[normal]">
                  1.연동할 카페24의 Mall ID(쇼핑몰 계정 ID)를 입력합니다.
                  <br />
                  2. ‘카페24 계정 연동&#39; 버튼 클릭 후 자동으로 새창에 카페24
                  로그인 화면이 나오게 됩니다.
                  <br />
                  3.연결할 카페24 계정으로 로그인 후 이어서 헤드리스 앱 사용
                  동의를 하세요.
                  <br />
                  <br />※ 더 자세한 사항은 아래 가이드 버튼을 통해 연결 가이드를
                  확인해 보세요.
                </p>
              </div>
              <div className="h-6"></div>
              <div className="rounded-md bg-[#F2F4F7] h-10 w-48 gap-2 flex text-center items-center justify-center">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask
                    id="mask0_45_3130"
                    maskUnits="userSpaceOnUse"
                    x="1"
                    y="0"
                    width="14"
                    height="16"
                  >
                    <path d="M14.75 0.25H1.25V15.75H14.75V0.25Z" fill="white" />
                  </mask>
                  <g mask="url(#mask0_45_3130)">
                    <path
                      d="M14 1H5V11H14V1Z"
                      stroke="#474A4E"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8 11H4C3.46957 11 2.96086 11.2107 2.58579 11.5858C2.21071 11.9609 2 12.4696 2 13C2 13.5304 2.21071 14.0391 2.58579 14.4142C2.96086 14.7893 3.46957 15 4 15H14C13.4564 14.461 13.105 13.7583 13 13"
                      stroke="#474A4E"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M1.99995 13V2.99995C1.99436 2.73577 2.04227 2.4732 2.1408 2.22802C2.23933 1.98284 2.38645 1.76013 2.57329 1.57329C2.76013 1.38645 2.98284 1.23933 3.22802 1.1408C3.4732 1.04227 3.73577 0.994361 3.99995 0.99995H8.99995"
                      stroke="#474A4E"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>
                <a href="https://local-room-f00.notion.site/24-eb52fe80e0604242b27ed1ed4969344c">
                  <div>카페24 연결 가이드</div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full rounded-b-md flex justify-end p-2 gap-2 bg-slate-200">
          <button
            onClick={handleClose}
            className="bg-white border-gray-300 border-2 rounded-md w-[91px] h-[34px]  flex items-center justify-center"
          >
            취소
          </button>
          <button
            onClick={connectData}
            className={`${idConfirmed ? ' bg-[#4046E3]' : 'bg-[#BCC2C9]'}  h-[34px] w-[96px] rounded-md text-white flex items-center justify-center`}
          >
            연결하기
          </button>
        </div>
      </div>
    </ModalBase>
  );
};
export default Cafe24Modal;
