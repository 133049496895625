import React from 'react';

interface CBCVButtonProps {
  label: string;
  disabled?: boolean;
  onClick?: () => void; // Add the onClick prop
}

const CBCVButton: React.FC<CBCVButtonProps> = ({ label, disabled = false, onClick }) => {
  
  const buttonStyle: React.CSSProperties = {
    backgroundColor: disabled ? 'var(--brand-colormainblack-0)' : 'var(--brand-colormainblue-0)',
    color: 'white',
    borderRadius: '6px',
    height: '50px',
    left: '90px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '427px',
    width: '480px',
    cursor: disabled ? 'not-allowed' : 'pointer',
    opacity: disabled ? 0.6 : 1,
  };

  return (
    <button style={buttonStyle} disabled={disabled} onClick={onClick}>
      {label}
    </button>
  );
}

export default CBCVButton;