import React, { useState, useEffect } from 'react';
import { Button } from '../../components/Button';
import './auth.css';
import ModalBase from '../common/ModalBase';
import CBCVButton from '../common/CBCVButton';
import { AuthProvider, useAuth } from '../Contexts/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import Checkbox from '../common/Checkbox';
import { useModalMessage } from '../common/CBCVModalMessageProvider';
import { useModal } from '../modal/ModalContext';
import { WorngPassword } from '../modals/WorngPassword';
import { clamp, formatDate } from '../common/Auxiliary';
import useRESTAPI from '../Contexts/RESTAPI';
import {API_HOST} from "../../config";
/*updateMessage('error','아이디 또는 비밀번호가 일치하지 않습니다'); */

export const LogIn = (): JSX.Element => {
  const [identity, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passworderror, setPassError] = useState('');

  const [autoLogin, setAutoLogin] = useState<boolean>(false);
  const [once, setOnce] = useState<boolean>(false);
  const { user_id ,normalSignIn, storedIdentity, storedPassword, loginAttempts } =
    useAuth();
  const { updateMessage } = useModalMessage();
  const navi = useNavigate();
  const { showModal, hideModal } = useModal();
  const {
    items: requests,
    fetchItems: fetchRequests,
  } = useRESTAPI(API_HOST + '/api/cbcv-request');
  
  useEffect(() => {
    const value = clamp(loginAttempts, 0, 5);

    if (value === 0) {
      setPassError('');
      
    } 
    else if (value > 4)
    {
      showModal(<WorngPassword onClose={hideModal}/>)
    }else {
      setPassError(
        `*비밀번호가 등록된 계정과 일치하지 않아요. 5회 이상 오류 시 비밀번호 재설정을 하셔야 해요. (${value}/5)`,
      );
    }
  }, [loginAttempts]);



  useEffect(() => {

    const _ = async (storedIdentity: string, storedPassword: string) => {
      try {
      const id = await normalSignIn(storedIdentity, storedPassword, autoLogin);
        
        
      const data =  await fetchRequests({ cbcv_user_id: id });
   
      if(data.length > 0 )
      {
        console.log('cvs');
        navi('/customer-value-status');
      }
      else{
        console.log('data');
        navi('/connect-data');
      }
      } catch (error) {}
    };

    if (!once && storedIdentity && storedPassword) {
      _(storedIdentity, storedPassword);
    }
    setOnce(true);
  }, []);

  const OnLogin = async () => {
    try {
      const id = await normalSignIn(identity, password, autoLogin);

      const data =  await fetchRequests({ cbcv_user_id: id });

      if(data.length > 0 )
        {
          console.log('cvs');
          navi('/customer-value-status');
        }
        else{
          console.log('data');
          navi('/connect-data');
        }


      return;
    } catch (error) {
      updateMessage('error', '올바르지 않은 아이디 또는 비밀번호');
    }
  };

  const renderCheckbox = (checked: boolean) => {
    return !checked ? (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.5"
          y="0.5"
          width="19"
          height="19"
          rx="3.5"
          stroke="#D8DDE3"
        />
      </svg>
    ) : (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_413_680)">
          <path
            d="M16 0H4C1.79086 0 0 1.79086 0 4V16C0 18.2091 1.79086 20 4 20H16C18.2091 20 20 18.2091 20 16V4C20 1.79086 18.2091 0 16 0Z"
            fill="#4046E3"
          />
          <path
            d="M15.7087 6.284C15.5184 6.10174 15.2651 6 15.0017 6C14.7382 6 14.4849 6.10174 14.2947 6.284L8.75168 11.659L5.70868 8.70901C5.51843 8.52676 5.26515 8.42499 5.00168 8.42499C4.73822 8.42499 4.48493 8.52676 4.29468 8.70901C4.20215 8.7976 4.12851 8.90401 4.0782 9.02182C4.02789 9.13963 4.00195 9.2664 4.00195 9.3945C4.00195 9.5226 4.02789 9.64937 4.0782 9.76718C4.12851 9.88499 4.20215 9.99143 4.29468 10.08L7.33768 13.03L8.04568 13.717C8.23572 13.8989 8.48862 14.0004 8.75168 14.0004C9.01474 14.0004 9.26765 13.8989 9.45768 13.717L10.1657 13.03L15.7087 7.65601C15.8014 7.5674 15.8751 7.46092 15.9255 7.34302C15.9759 7.22511 16.0019 7.09823 16.0019 6.97C16.0019 6.84178 15.9759 6.71489 15.9255 6.59698C15.8751 6.47908 15.8014 6.3726 15.7087 6.284Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_413_680">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  };

  return (
    <div className="login">
      <div className="div">
        <img
          className="img"
          alt="Element"
          src="/logo-b.png"
        />
        <div className="h-8"></div>
        <div className="overlap-group">
          <div className="text-wrapper-6">로그인</div>
          <div>
            <div className="frame-3">
              <div className="text-wrapper-7">아이디</div>
            </div>

            <input
              placeholder="아이디를 입력해 주세요."
              className="div-wrapper w-full placeholder:text-gray-400 text-black"
              value={identity}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div>
            <div className="frame-4">
              <div className="text-wrapper-7">비밀번호</div>
            </div>

            <input
              type="password"
              placeholder="비밀번호를 입력해 주세요."
              className="div-wrapper w-full placeholder:text-gray-400 text-black"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className="text-[10px] text-[#E53073]">{passworderror}</div>
          </div>

          <div className="flex justify-between">
            <div className="flex  gap-2">
              <Checkbox
                checked={autoLogin}
                onChange={setAutoLogin}
                renderCheckbox={renderCheckbox}
              ></Checkbox>
              <div className="text-wrapper-3">자동 로그인</div>
            </div>
            <Link to={'/findmyiD'}>
              <div className="text-wrapper-4">아이디/비밀번호 찾기</div>
            </Link>
          </div>
          <div className="overlap">
            <CBCVButton label="로그인하기" onClick={OnLogin}></CBCVButton>
          </div>
        </div>
        <div className="h-16"></div>
        <div className="flex justify-end items-center gap-3 ">
          <div className="text-wrapper-7">헤드리스 계정이 아직 없으신가요?</div>
          <Link to={'/signup'}>
            <button
              className={
                'px-[24px] py-[9px] bg-[#DDDFF8]  rounded-md  text-[#4046E3] flex items-center justify-center'
              }
            >
              회원가입하기
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LogIn;
