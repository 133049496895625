import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AccountIcon1 } from './components/icons/AccountIcon1';
import { NewsfeedIcon1 } from './components/icons/NewsfeedIcon1';
import { ProductIcon1 } from './components/icons/ProductIcon1';
import './CBCVTab.css';
import { PieChartIcon } from './components/icons/PieChartIcon/PieChartIcon';
import { useAuth } from './components/Contexts/AuthContext';

interface TabItem {
  text: string;
  url: string;
}

const tabs: TabItem[] = [
  { text: '고객 가치 현황', url: '/customer-value-status' },
  { text: '미래 예상 매출', url: '/future-revenue-forecast' },
  { text: '시나리오별 예상 매출', url: '/scenario-based-revenue-forecast' },
  { text: '고객 현황', url: '/customer-status' },
  { text: '미래 고객', url: '/future-customers' },
  { text: '지난 1년간 고객', url: '/customers-last-year' },
  { text: '재구매율 높은 상품', url: '/high-repurchase-products' },
  { text: '마중물 상품', url: '/priming-products' },
  { text: '계절별 상품', url: '/seasonal-products' },
  { text: '상품 리스트', url: '/product-list' },
  { text: '고객 리스트', url: '/customer-list' },
];
//기본 탭입니다
export const CBCVTab = (): JSX.Element => {
  const location = useLocation();
  const { admin_id } = useAuth();

  const getLinkWithQuery = (url: string) => {
    const searchParams = new URLSearchParams(location.search);
    return `${url}?${searchParams.toString()}`;
  };

  return (
    <div className="cvcvtab min-h-screen max-h-full">
      <div className="headless-wrapper">
        <img
          className="headless-logo"
          alt="Headless logo"
          src="/logo.png"
        />
      </div>
      <div className="line-box">
        <div className="line" />
      </div>
      <div className="tab-item">
        <NewsfeedIcon1 className="icon" />
        <div className="sec-text">브랜드 가치 분석</div>
      </div>

      {tabs.slice(0, 3).map((item, index) => (
        <div
          className={`tab-item ${location.pathname.startsWith(item.url) ? 'tab-item-selected' : ''}`}
          key={index}
        >
          <div className="icon"></div>
          <Link to={getLinkWithQuery(item.url)} className="link">
            <div className="text">{item.text}</div>
          </Link>
        </div>
      ))}

      <div className="line-box">
        <div className="line" />
      </div>
      <div className="tab-item">
        <AccountIcon1 className="icon"></AccountIcon1>
        <div className="sec-text">고객 분석</div>
      </div>

      {tabs.slice(3, 6).map((item, index) => (
        <div
        className={`tab-item ${location.pathname.startsWith(item.url) ? 'tab-item-selected' : ''}`}
          key={index}
        >
          <div className="icon"></div>
          <Link to={getLinkWithQuery(item.url)} className="link">
            <div className="text">{item.text}</div>
          </Link>
        </div>
      ))}

      <div className="line-box">
        <div className="line" />
      </div>
      <div className="tab-item">
        <ProductIcon1 className="icon"></ProductIcon1>
        <div className="sec-text">상품 분석</div>
      </div>

      {tabs.slice(6, 9).map((item, index) => (
        <div
        className={`tab-item ${location.pathname.startsWith(item.url) ? 'tab-item-selected' : ''}`}
          key={index}
        >
          <div className="icon"></div>
          <Link to={getLinkWithQuery(item.url)} className="link">
            <div className="text">{item.text}</div>
          </Link>
        </div>
      ))}
         <div className="line-box">
        <div className="line" />
      </div>
      <div className="tab-item">
        <PieChartIcon className="icon"></PieChartIcon>
        <div className="sec-text">DB 분석</div>
      </div>
      {tabs.slice(9, 11).map((item, index) => (
        <div
          className={`tab-item ${location.pathname.startsWith(item.url) ? 'tab-item-selected' : ''}`}
          key={index}
        >
          <div className="icon"></div>
          <Link to={getLinkWithQuery(item.url)} className="link">
            <div className="text">{item.text}</div>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default CBCVTab;
